import { Table } from 'antd'
import {
  TableProps,
  ColumnsType,
  ColumnType,
  ColumnGroupType
} from 'antd/lib/table'
import styled from 'styled-components'
import { InputType, SelectOptionData } from '@veracity/vui'
import { EditableCell } from './EditableCell'
import { Control } from 'react-hook-form'

const StyleWrapper = styled.div`
  padding-top: 1em;
  // TODO: Implement some styles to aligin with current VUI design
`

export interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
  editable?: boolean
  inputType?: InputType | 'select'
  formControlName?: string
  selectValues?: SelectOptionData[]
}
export type EditableColumnsType<RecordType = unknown> = (
  | EditableColumnGroupType<RecordType>
  | EditableColumnType<RecordType>
)[]

export interface EditableColumnGroupType<RecordType>
  extends Omit<ColumnGroupType<RecordType>, 'children'>,
    EditableColumnType<RecordType> {
  children: EditableColumnsType<RecordType>
}

export interface BaseColumnType<RecordType> extends ColumnsType<RecordType> {}

export interface BaseTableProps<RecordType extends object = any>
  extends TableProps<RecordType> {
  columns: EditableColumnsType<RecordType>
  isRowInEdit?: (rowData: RecordType) => boolean
  control?: Control<any>
}

export const BaseTable = <T extends object = any>({
  columns,
  control,
  isRowInEdit,
  ...rest
}: BaseTableProps<T>) => {
  return (
    <StyleWrapper>
      <Table
        {...rest}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        columns={columns?.map(col =>
          !col.editable
            ? col
            : ({
                ...col,
                onCell: (record: T) => ({
                  inputType: col.inputType,
                  dataIndex: col.dataIndex,
                  title: col.title,
                  editing: isRowInEdit && isRowInEdit(record),
                  control: control,
                  formControlName: col.formControlName,
                  selectValues: col.selectValues
                })
              } as EditableColumnGroupType<T>)
        )}
      />
    </StyleWrapper>
  )
}
