import {
  LoggedInHeader,
  LoggedOutHeader,
  Header as VuiHeader,
  HeaderSignIn,
  HeaderAccount,
  List
} from '@veracity/vui'
import { useSelector } from 'react-redux'
import { Link as RRLink, useLocation } from 'react-router-dom'
import { UserRoles } from '../../api/users'
import { RootState } from '../../store'
import TextLogo from '../styled/TextLogo'

const Header = () => {
  const { pathname } = useLocation()
  const isLoggedIn = useSelector<RootState, boolean | undefined>(
    state => state.user.isLoggedIn
  )
  const isLoggedAs = useSelector<RootState, UserRoles>(
    state => UserRoles[state.user.role as keyof typeof UserRoles]
  )
  const displayName = useSelector<RootState, string | undefined>(
    state => state.user.fullName
  )
  const _headerLinks: { label: string; to: string; level: number }[] = [
    { label: 'Countries', to: '/countries', level: UserRoles.Moderator },
    { label: 'Documents', to: '/documents', level: UserRoles.Moderator },
    { label: 'Users', to: '/users', level: UserRoles.Admin },
    { label: 'Data seed', to: '/data-seed', level: UserRoles.Admin }
  ].filter(({ level }) => isLoggedAs >= level)

  const headerLinks = _headerLinks.map(({ label, to }) => (
    <VuiHeader.LinkItem
      key={label}
      isActive={pathname.startsWith(to)}
      linkProps={{ as: RRLink, to }}
      text={label}
    />
  ))

  return isLoggedIn ? (
    <LoggedInHeader
      logo={<VuiHeader.Logo brand="dnv" url="/" />}
      mainLinks={
        <VuiHeader.MainLinks>
          {headerLinks}

          {headerLinks.length > 0 && (
            <VuiHeader.Divider m="auto 16px auto 0"></VuiHeader.Divider>
          )}

          <TextLogo text="DiGCCE Back Office" size="title" mr={1} />
        </VuiHeader.MainLinks>
      }
      account={
        <HeaderAccount
          sections={
            <List isInteractive>
              <List.Divider />
              <a href="/account/signout">
                <List.Item iconLeft="falSignOut" text="Sign out" />
              </a>
            </List>
          }
          userInfo={{ displayName, companyName: 'DNV' }}
        />
      }
    />
  ) : (
    <LoggedOutHeader
      logo={<VuiHeader.Logo brand="dnv" url="/" />}
      mainLinks={<TextLogo text="DiGCCE Back Office" size="title" mr={1} />}
      signIn={<HeaderSignIn url="/account/signin" />}
    />
  )
}

export default Header
