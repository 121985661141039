import { Box, Card, Grid, Image } from '@veracity/vui'
import { KeyCharacteristicsResult } from '../../../api/country-requirement-matrixes'
import { KeyCharacteristicTypeResult } from '../../../api/key-characteristics-types'
import { ImageValidator } from '../../../components/ImageValidator'
import { CONFIGURATION } from '../../../configuration'
import { KeyCharacteristicPreview } from './KeyCharacteristicPreview'
import { KeyCharacteristicTablePreview } from './KeyCharacteristicTablePreview'
export interface KeyCharacteristicInfoProps {
  keyCharacteristic: KeyCharacteristicsResult
  keyCharacteristicTypes: KeyCharacteristicTypeResult[]
}

export const KeyCharacteristicInfo = ({
  keyCharacteristic,
  keyCharacteristicTypes
}: KeyCharacteristicInfoProps) => {
  const keyCharacteristicType = keyCharacteristicTypes.find(
    x => x.id === keyCharacteristic.keyCharacteristicTypeId
  )
  return (
    <Card p={2}>
      <Grid
        gap={1}
        gridAutoRows="minmax(100px, auto)"
        gridTemplateColumns="10em 5em auto">
        <Box center>{keyCharacteristicType?.name}</Box>
        <Box center>
          <KeyCharacteristicPreview>
            {keyCharacteristic.plotData ? (
              <KeyCharacteristicTablePreview
                jsonSchemaStr={keyCharacteristicType?.tableDataJsonSchema || ''}
                jsonDataStr={keyCharacteristic.plotData || '[]'}
              />
            ) : (
              <ImageValidator
                imageSrc={`${CONFIGURATION.URLS.KEY_CHARACTERISTICS}/${keyCharacteristic.id}`}>
                <Image
                  src={`${CONFIGURATION.URLS.KEY_CHARACTERISTICS}/${keyCharacteristic.id}`}
                  alt="Key Characteristic Chart"
                  width={20}
                />
              </ImageValidator>
            )}
          </KeyCharacteristicPreview>
        </Box>
        <Box center>{keyCharacteristic.comment}</Box>
      </Grid>
    </Card>
  )
}
