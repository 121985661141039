import { Box, Button } from '@veracity/vui'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ModalDialog } from '../../components/ModalDialog'
import { useEffect } from 'react'
import {
  DocumentModel,
  DocumentType,
  DocumentStatus,
  DocumentLanguage,
  DocumentAvailability
} from '../../api/documents'
import { useFetchDocument } from '../../hooks/useFetchDocument'
import {
  DatePickerInput,
  InputFormControl,
  SelectFormControl,
  TextareaFormControl
} from '../../components/Form'
import { FileUploadFormControl } from '../../components/Form/FileUploadFormControl'

export interface DocumentEditModalProps {
  isOpen: boolean
  id?: string
  onClose: () => void
  isSaveInProgress: boolean
  onSaveClicked: (model: DocumentModel, id?: string) => void
}

const documentSectionSchema = yup.object({
  name: yup.string().required(),
  shortName: yup.string().required(),
  type: yup.number().required(),
  officialUrl: yup.string().notRequired(),
  file: yup.mixed(),
  status: yup.number().required(),
  author: yup.string().required(),
  language: yup.number().required(),
  availability: yup.number().required()
})

export const DocumentEditModal = ({
  id,
  isOpen,
  onClose,
  isSaveInProgress,
  onSaveClicked
}: DocumentEditModalProps) => {
  const { inProgress, document } = useFetchDocument(id)
  const { control, handleSubmit, reset } = useForm<DocumentModel>({
    resolver: yupResolver(documentSectionSchema)
  })

  useEffect(() => {
    reset({
      name: document?.name || '',
      author: document?.author || '',
      comment: document?.comment || '',
      shortName: document?.shortName || '',
      officialUrl: document?.officialUrl || '',
      keywords: document?.keywords || [],
      status: document?.status === undefined ? undefined : document.status,
      type:
        document?.documentType === undefined
          ? undefined
          : document.documentType,
      language:
        document?.language === undefined ? undefined : document.language,
      releaseDate:
        document?.releaseDate === undefined
          ? undefined
          : new Date(document.releaseDate),
      availability:
        document?.availability === undefined
          ? undefined
          : document.availability,
      file: undefined
    })
  }, [document, reset])

  const onSubmit = (data: DocumentModel) => {
    if ((!id && !!data.file) || !!id) onSaveClicked(data, id)
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Document"
      inProgress={inProgress || isSaveInProgress}
      modalHeight="70%"
      modalWidth="60%">
      <InputFormControl formControlName="name" control={control} label="Name" />

      <InputFormControl
        formControlName="shortName"
        control={control}
        label="Short Name"
      />

      <InputFormControl
        formControlName="officialUrl"
        control={control}
        label="Official Url"
      />

      <SelectFormControl
        formControlName="type"
        control={control}
        label="Type"
        options={[
          {
            text: 'Grid Code Requirements',
            value: DocumentType.GridCodeRequirements
          },
          {
            text: 'Guidelines and Processes ',
            value: DocumentType.GuidelinesAndProcesses
          },
          { text: 'Other', value: DocumentType.Other }
        ]}
      />

      <SelectFormControl
        formControlName="status"
        control={control}
        label="Status"
        options={[
          { text: 'Active', value: DocumentStatus.Active },
          { text: 'Draft', value: DocumentStatus.Draft },
          { text: 'Inactive', value: DocumentStatus.Inactive }
        ]}
      />

      <SelectFormControl
        formControlName="language"
        control={control}
        label="Language"
        options={[
          { text: 'Native', value: DocumentLanguage.Native },
          { text: 'Official', value: DocumentLanguage.Official },
          { text: 'UnOfficial', value: DocumentLanguage.UnOfficial }
        ]}
      />

      <SelectFormControl
        formControlName="availability"
        control={control}
        label="Availability"
        options={[
          { text: 'Native', value: DocumentAvailability.Bought },
          { text: 'Official', value: DocumentAvailability.Public }
        ]}
      />

      <DatePickerInput
        control={control}
        formControlName="releaseDate"
        label="Publish"
      />

      <InputFormControl
        formControlName="author"
        control={control}
        maxLength={256}
        label="Author"
      />

      <TextareaFormControl
        formControlName="comment"
        control={control}
        label="Comment"
        maxLength={250}
      />

      <FileUploadFormControl
        formControlName="file"
        control={control}
        accept="application/pdf"
      />

      <Box p="1em 1em 0" w="100%" justifyContent="flex-end">
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </Box>
    </ModalDialog>
  )
}
