import { useEffect, useState } from 'react'
import { DocumentResult, getDocument } from '../api/documents'

export const useFetchDocument = (documentId?: string) => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<DocumentResult>()
  const [error, setError] = useState<string>()
  useEffect(() => {
    setData(undefined)
    setError(undefined)
    if (documentId) {
      setInProgress(true)
      getDocument(documentId)
        .then(res => {
          setInProgress(false)
          setData(res)
        })
        .catch(err => {
          setInProgress(false)
          setError(err.message)
        })
    }
  }, [documentId])

  return {
    inProgress,
    document: data,
    error
  }
}
