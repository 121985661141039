import { ListItem, List, Icon } from '@veracity/vui'
import {
  ComplianceLevel,
  IsComplianceApplicable
} from '../../api/country-requirement-matrixes'

export const RfgClassificationTableBox = (
  rfgTypeId: string,
  technologyMatricesGroup: object
) => {
  const tmIndex = Object.keys(technologyMatricesGroup).indexOf(rfgTypeId)
  const technologyMatrix = Object.values(technologyMatricesGroup)[tmIndex]

  const { compliances, keyCharacteristics, condensedSummaries } =
    technologyMatrix

  const pocUnitLevel = compliances?.find(
    (compliance: { level: ComplianceLevel }) =>
      compliance.level === ComplianceLevel.Unit
  )?.isApplicable

  const pocProjectLevel = compliances?.find(
    (compliance: { level: ComplianceLevel }) =>
      compliance.level === ComplianceLevel.Project
  )?.isApplicable

  const complianceToIcon = (isApplicable: IsComplianceApplicable) => {
    if (isApplicable === IsComplianceApplicable.Yes)
      return <Icon name="falCheckCircle" size="sm" ml={1} />
    if (isApplicable === IsComplianceApplicable.NotAvailable)
      return <Icon name="falQuestionCircle" size="sm" ml={1} />
    return <Icon name="falTimesCircle" size="sm" ml={1} />
  }

  return (
    <List
      fontSize="sm"
      data-rfg-type-id={rfgTypeId}
      data-matrix-id={technologyMatrix.id}>
      <ListItem p={0} fontSize="sm">
        Unit Level Compliance: {complianceToIcon(pocUnitLevel)}
      </ListItem>
      <ListItem p={0} fontSize="sm">
        Project Level Compliance: {complianceToIcon(pocProjectLevel)}
      </ListItem>
      <ListItem p={0} fontSize="sm">
        Key Characteristics number: {keyCharacteristics.length}
      </ListItem>
      <ListItem p={0} fontSize="sm">
        Condensed summary: {condensedSummaries.length > 0 ? '' : 'not'}
        &nbsp;added
      </ListItem>
    </List>
  )
}
