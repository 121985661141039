import { Box, Icon, Table, Tbody, Td, Th, Thead, Tr } from '@veracity/vui'
import {
  ComplianceLevel,
  ComplianceResult,
  IsComplianceApplicable
} from '../../api/country-requirement-matrixes'
import { Empty } from '../../components/Empty'
import { DocumentSectionResult } from '../../api/document-sections'
import { OpenDocumentButton } from '../../components/OpenDocumentButton'

export interface CompliancesTableProps {
  compliances: ComplianceResult[]
}

export const CompliancesTable = ({ compliances }: CompliancesTableProps) => {
  const getIconName = (isApplicable: IsComplianceApplicable) => {
    switch (isApplicable) {
      case IsComplianceApplicable.Yes:
        return 'falCheckCircle'
      case IsComplianceApplicable.No:
        return 'falTimesCircle'
      default:
        return 'falQuestionCircle'
    }
  }

  const getLevelName = (level: ComplianceLevel) => {
    switch (level) {
      case ComplianceLevel.Project:
        return 'Project'
      case ComplianceLevel.Unit:
        return 'Unit'
      default:
        return '-'
    }
  }

  const getDocumentSectionName = (documentSection?: DocumentSectionResult) =>
    !documentSection
      ? '-'
      : `${documentSection.documentShortName} sec. ${documentSection.name}`

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th title="" />
            <Th title="Level" />
            <Th title="Method" />
            <Th title="Document Section" />
            <Th title="Comment" />
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {compliances.map(compliance => (
            <Tr key={compliance.id}>
              <Td>
                <Icon name={getIconName(compliance.isApplicable)} size="sm" />
              </Td>
              <Td>{getLevelName(compliance.level)}</Td>
              <Td>{compliance.complianceMethod?.name || '-'}</Td>
              <Td>
                <Box>
                  {getDocumentSectionName(compliance.documentSection)}
                  {compliance.documentSection && (
                    <OpenDocumentButton
                      documentId={compliance.documentSection.documentId}
                      page={compliance.documentSection.page}
                    />
                  )}
                </Box>
              </Td>
              <Td>{compliance.comment || '-'}</Td>
              <Td></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {compliances.length === 0 && <Empty />}
    </>
  )
}
