import { Textarea, TextareaProps } from '@veracity/vui'
import { BaseFormItemProps } from './BaseFormItem'
import { Controller } from 'react-hook-form'
import { FormLabel } from './FormLabel'

export type TextareaBaseFormProps = BaseFormItemProps & TextareaProps

export interface TextareaFormControlProps extends TextareaBaseFormProps { }

export const TextareaFormControl = ({
  control,
  formControlName,
  label,
  ...rest
}: TextareaFormControlProps) => (
  <Controller
    name={formControlName}
    control={control}
    render={({ field, fieldState }) => (
      <>
        {!label ? null : <FormLabel label={label} />}
        <Textarea
          {...rest}
          isInvalid={!!fieldState.error}
          value={field.value}
          onChange={field.onChange}
        />
      </>
    )}
  />
)
