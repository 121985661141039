import { Box, Button, Divider, H1, IconButton } from '@veracity/vui'
import { useMemo, useState } from 'react'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DocumentSectionModel } from '../../api/document-sections'
import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { useFetchDocumentSections } from '../../hooks/useFetchDocumentSections'
import { useSendUpdateDocumentSection } from '../../hooks/useSendUpdateDocumentSection'
import { useFetchDocument } from '../../hooks/useFetchDocument'
import { OpenDocumentButton } from '../../components/OpenDocumentButton'
import { DocumentSectionResult } from './../../api/document-sections'
import { BaseTable, EditableColumnsType } from '../../components/Table'

const documentSectionSchema = yup.object({
  name: yup.string().required(),
  page: yup.number().positive().integer().notRequired(),
  description: yup.string().notRequired()
})

export const DocumentSectionsPage = () => {
  const { documentId } = useParams()
  const [selectedToEditId, setSelectedToEditId] = useState<string>()
  const [additionalRowVisible, setAdditionalRowVisible] = useState(false)
  const { document } = useFetchDocument(documentId)
  const { control, handleSubmit, setValue, resetField } =
    useForm<DocumentSectionModel>({
      resolver: yupResolver(documentSectionSchema),
      defaultValues: {
        documentId: documentId
      }
    })

  const {
    inProgress: fetchInProgress,
    documentSections,
    fetchDocumentSections
  } = useFetchDocumentSections(documentId)

  const { inProgress: updateInProgress, sendUpdateRequest } =
    useSendUpdateDocumentSection()

  const handleEditRowClick = (id?: string) => {
    setSelectedToEditId(id)
    const selectedSection = documentSections?.find(x => x.id === id)
    setValue('name', selectedSection?.name || '')
    setValue('page', selectedSection?.page || 0)
    setValue('description', selectedSection?.description || '')
  }

  const handleAddRowClick = () => {
    setAdditionalRowVisible(true)
    resetFormValues()
  }

  const tableData: DocumentSectionResult[] | undefined = useMemo(() => {
    if (!documentSections) return undefined
    return !additionalRowVisible
      ? [...documentSections]
      : [{ id: '' } as DocumentSectionResult, ...documentSections]
  }, [additionalRowVisible, documentSections])

  const handleEditRowEditCancel = () => {
    setSelectedToEditId(undefined)
    setAdditionalRowVisible(false)
    resetFormValues()
  }

  const resetFormValues = () => {
    resetField('name')
    resetField('page', { defaultValue: '' })
    resetField('description')
  }

  const handleEditRowSave = (model: DocumentSectionModel, id?: string) => {
    sendUpdateRequest(model, id).then(() => {
      setSelectedToEditId(undefined)
      setAdditionalRowVisible(false)
      resetFormValues()
      return fetchDocumentSections()
    })
  }

  const isRowInEdit = (rowData: DocumentSectionResult) =>
    !!rowData.id ? rowData.id === selectedToEditId : additionalRowVisible

  const columns: EditableColumnsType<any> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      editable: true,
      formControlName: 'name',
      defaultSortOrder: 'ascend'
    },
    {
      key: 'page',
      title: 'Page',
      dataIndex: 'page',
      width: '15%',
      editable: true,
      formControlName: 'page',
      sorter: (a, b) => a.page - b.page
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      editable: true,
      formControlName: 'description',
      sorter: (a, b) => a.description.length - b.description.length
    },
    {
      title: '',
      width: '15%',
      dataIndex: 'operation',
      // TODO: Add support for static types in BaseTable Component
      render: (_: any, documentSection: DocumentSectionResult) => {
        //TODO: Extract component
        return (
          <Box gap={1}>
            {isRowInEdit(documentSection) ? (
              <>
                <IconButton
                  icon="falSave"
                  size="sm"
                  onClick={handleSubmit(
                    data => handleEditRowSave(data, documentSection.id),
                    (a, b) => {
                      console.log('error', a, b)
                    }
                  )}
                />
                <IconButton
                  icon="falTimes"
                  size="sm"
                  onClick={() => handleEditRowEditCancel()}
                />
              </>
            ) : (
              <>
                <OpenDocumentButton
                  documentId={documentSection.documentId}
                  page={documentSection.page}
                />

                <IconButton
                  icon="falPen"
                  size="sm"
                  onClick={() => handleEditRowClick(documentSection.id)}
                />
              </>
            )}
          </Box>
        )
      }
    }
  ]

  return (
    <Box column minW="100%" pb="1em">
      <PageBreadcrumbs
        items={[
          { label: 'Documents', to: '/documents' },
          { label: `Document Sections for ${document?.shortName}` }
        ]}
      />

      <Box p="0 1em" justifyContent="space-between" center>
        <H1>Document Sections</H1>
        <Button
          iconLeft="falPlus"
          text="Add"
          disabled={additionalRowVisible || !!selectedToEditId}
          onClick={handleAddRowClick}
        />
      </Box>

      <Divider borderColor="seaBlue.28" />

      <BaseTable<DocumentSectionResult>
        loading={fetchInProgress || updateInProgress}
        dataSource={tableData}
        columns={columns}
        rowKey="id"
        isRowInEdit={isRowInEdit}
        control={control}
        pagination={false}
      />
    </Box>
  )
}
