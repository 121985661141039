import { Table } from '@veracity/vui'
import { tryParseJson } from '../../../utils/json-utils'

export interface KeyCharacteristicTablePreviewProps {
  jsonDataStr: string
  jsonSchemaStr: string
}

export const KeyCharacteristicTablePreview = ({
  jsonDataStr,
  jsonSchemaStr
}: KeyCharacteristicTablePreviewProps) => {
  
  const _jsonSchema: { id: string; label: string }[] = tryParseJson(jsonSchemaStr)
  const _jsonData = tryParseJson(jsonDataStr)

  return (
    <Table
      columns={_jsonSchema.map(({ id, label }) => {
        return { field: id, title: label }
      })}
      rows={_jsonData}
    />
  )
}
