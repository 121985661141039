import { IconButton } from '@veracity/vui'
import { useCallback } from 'react'
import { CONFIGURATION } from '../configuration'

export interface OpenDocumentButtonProps {
  documentId?: string
  page?: number
}

export const OpenDocumentButton = ({
  documentId,
  page
}: OpenDocumentButtonProps) => {
  const handleOpenDocumentClick = useCallback(() => {
    const path = CONFIGURATION.URLS.DOCUMENT
    if (!!documentId && !!page) {
      window.open(`${path}/${documentId}#page=${page}`, '_blank')
    } else if (!!documentId) {
      window.open(`${path}/${documentId}`, '_blank')
    }
  }, [documentId, page])
  return (
    <IconButton
      icon="falFilePdf"
      size="sm"
      title="Open"
      onClick={handleOpenDocumentClick}
    />
  )
}
