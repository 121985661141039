import { Box, Button, Card, Heading } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { UserRoles } from '../../api/users'
import CenterAbsolute from '../../components/styled/CenterAbsolute'
import { RootState } from '../../store'

const HomePage = () => {
  const isLoggedAs: UserRoles = useSelector<RootState, UserRoles>(
    state => UserRoles[state.user.role as keyof typeof UserRoles]
  )

  if (isLoggedAs > 0) return <Navigate to="/countries" />

  return (
    <CenterAbsolute>
      <Card align="center" py={5} px={8} position="relative" column>
        <Heading
          text="DiGCCE Back Office"
          size="title"
          mr={1}
          mb={1}
          fontSize={48}
        />

        {!isLoggedAs && isLoggedAs === 0 && (
          <Heading
            text="You Don't Have Permission To Access on This Site"
            size="lead"
            m={1}
            mb={0}
            fontSize={24}
          />
        )}

        {isLoggedAs === undefined && (
          <Box gap={4} pt={4}>
            <a href="https://id.veracity.com/sign-up">
              <Button iconLeft="falUserPlus" size="xl" variant="secondaryDark">
                Sign up
              </Button>
            </a>
            <a href="/account/signin">
              <Button iconLeft="falSignInAlt" size="xl">
                Sign in
              </Button>
            </a>
          </Box>
        )}
      </Card>
    </CenterAbsolute>
  )
}
export default HomePage
