import { Box, DragAndDrop, H1, Spinner, useToast } from '@veracity/vui'
import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { CONFIGURATION } from '../../configuration'
import { useState } from 'react'
import { uploadSeedDataFile } from '../../api/seed-data'
import { getErrorMessage } from '../../utils/http-utils'

export const DataSeedPage = () => {
  const [inProgress, setInProgress] = useState(false)
  const { showSuccess, showError } = useToast()

  const handleFilesAdded = ([file]: File[]) => {
    setInProgress(true)
    uploadSeedDataFile(file)
      .then(() => {
        showSuccess('The data has been successfully imported')
      })
      .catch(err => {
        showError(getErrorMessage(err))
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  return (
    <Box column minW="100%" pb="1em">
      <PageBreadcrumbs items={[{ label: 'Data Seed' }]} />

      <Box p="0 1em" mb={2} justifyContent="space-between" center>
        <H1>Data Seed</H1>
      </Box>

      {inProgress ? (
        <Spinner text="Uploading data..." m={10} />
      ) : (
        <DragAndDrop
          accept={CONFIGURATION.SEED_DATA.FILE_TYPE}
          onFilesAdded={handleFilesAdded}
        />
      )}
    </Box>
  )
}
