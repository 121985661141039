import { useState } from 'react'
import { CountryRequirementMatrixDetailsResult } from '../api/country-requirement-matrixes'

enum TabMarkings {
  unTouched = -1,
  touched = 0,
  ready = 1
}

enum ReadyFlags {
  areDocumentsReadyFlag,
  areCondensedSummariesReadyFlag,
  areKeyCharacteristicsReadyFlag,
  areCompliancesReadyFlag
}

const tabsMarkingsToEnum = (flag: boolean) => {
  if (flag === null) return TabMarkings.unTouched
  if (flag === true) return TabMarkings.ready
  return TabMarkings.touched
}
export const useMarkingsOnTabs = (
  matrix: CountryRequirementMatrixDetailsResult
) => {
  const {
    areDocumentsReadyFlag,
    areCondensedSummariesReadyFlag,
    areKeyCharacteristicsReadyFlag,
    areCompliancesReadyFlag
  } = matrix

  const [tabMarks, setTabMarks] = useState([
    tabsMarkingsToEnum(areDocumentsReadyFlag),
    tabsMarkingsToEnum(areCondensedSummariesReadyFlag),
    tabsMarkingsToEnum(areKeyCharacteristicsReadyFlag),
    tabsMarkingsToEnum(areCompliancesReadyFlag)
  ])

  const mapToJson = () => {
    return {
      areDocumentsReadyFlag:
        tabMarks[ReadyFlags.areDocumentsReadyFlag] === TabMarkings.ready,
      areCondensedSummariesReadyFlag:
        tabMarks[ReadyFlags.areCondensedSummariesReadyFlag] ===
        TabMarkings.ready,
      areKeyCharacteristicsReadyFlag:
        tabMarks[ReadyFlags.areKeyCharacteristicsReadyFlag] ===
        TabMarkings.ready,
      areCompliancesReadyFlag:
        tabMarks[ReadyFlags.areCompliancesReadyFlag] === TabMarkings.ready
    }
  }

  const tabMarking = (activeTab: number, tabNum: number) => {
    if (activeTab === tabNum) {
      const _tabMarks = [...tabMarks]
      _tabMarks[tabNum] =
        _tabMarks[tabNum] === TabMarkings.ready
          ? TabMarkings.touched
          : TabMarkings.ready
      setTabMarks(_tabMarks)
    }
    return tabNum
  }

  const tabMarksToIcon = (tabNum: number) => {
    const currentMark = tabMarks[tabNum]
    if (currentMark === TabMarkings.unTouched) return 'cusDotFull'
    if (currentMark === TabMarkings.ready) return 'falCheckCircle'
    return 'falCircle'
  }

  return {
    tabMarking,
    tabMarksToIcon,
    mapToJson
  }
}
