import { useEffect, useState } from 'react'
import {
  DocumentSectionGroupedResult,
  getCountryDocumentsSections
} from '../api/document-sections'

export const useFetchDocumentsSectionsGrouped = (id: string) => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<DocumentSectionGroupedResult[]>()
  const [error, setError] = useState<string>()

  const fetchDocumentsSections = (countryId: string) => {
    setInProgress(true)
    setData(undefined)
    setError(undefined)
    getCountryDocumentsSections(countryId)
      .then(res => setData(res))
      .catch(err => setError(err.message))
      .finally(() => setInProgress(false))
  }

  useEffect(() => {
    fetchDocumentsSections(id)
  }, [id])

  return {
    inProgress,
    documents: data,
    error,
    fetchDocumentsSections
  }
}
