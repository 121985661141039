import { cloneElement, isValidElement, PropsWithChildren } from 'react'
import { Controller, Control } from 'react-hook-form'
import { FormLabel } from './FormLabel'

export interface BaseFormItemProps extends PropsWithChildren {
  label?: string
  control: Control<any>
  formControlName: string
}

export const BaseFormItem = ({
  control,
  label,
  formControlName,
  children
}: BaseFormItemProps) => (
  <Controller
    name={formControlName}
    control={control}
    render={({ field, fieldState }) => (
      <>
        {!label ? null : <FormLabel label={label} />}
        {isValidElement(children)
          ? cloneElement(children, {
              value: field.value,
              onChange: field.onChange,
              variant: !!fieldState.error ? 'red' : undefined
            } as any)
          : children}
      </>
    )}
  />
)
