import axios from './base'

export const getComplianceMethods = () =>
  axios
    .get('/api/ComplianceMethods')
    .then(response => response.data as ComplianceMethodsResults[])

export interface ComplianceMethodsResults {
  id: string
  name: string
}
