import { Box } from '@veracity/vui'
import ReactMarkdown from 'react-markdown'
import MDWrapper from './styled/MDWrapper'

export interface MarkdownSummaryBoxProps {
  markdown: string
}

export const MarkdownSummaryBox = ({ markdown }: MarkdownSummaryBoxProps) => (
  <Box column bg="sandstone.85" m={1} p={1}>
    <MDWrapper>
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </MDWrapper>
  </Box>
)
