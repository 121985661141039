import { useEffect, useState } from 'react'
import { getUsersList, UsersListResult } from '../api/users'

export const useFetchUsersList = () => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<UsersListResult[]>()
  const [error, setError] = useState<string>()

  const fetchUsersList = () => {
    setInProgress(true)
    setData(undefined)
    setError(undefined)

    getUsersList()
      .then(res => setData(res))
      .catch(err => setError(err.message))
      .finally(() => setInProgress(false))

    setInProgress(false)
  }

  useEffect(() => {
    fetchUsersList()
  }, [])

  return {
    inProgress,
    usersList: data,
    error,
    fetchUsersList
  }
}
