import { Select, SelectProps } from '@veracity/vui'
import { Controller } from 'react-hook-form'
import { BaseFormItemProps } from './BaseFormItem'
import { FormLabel } from './FormLabel'

export type SelectFormControlProps = BaseFormItemProps & SelectProps

export const SelectFormControl = ({
  formControlName,
  control,
  label,
  ...props
}: SelectFormControlProps) => (
  <Controller
    name={formControlName}
    control={control}
    render={({ field, fieldState }) => (
      <>
        {!label ? null : <FormLabel label={label} />}
        <Select
          value={field.value}
          onChange={field.onChange}
          isInvalid={!!fieldState.error}
          maxHeight={320}
          {...props}
        />
      </>
    )}
  />
)
