import styled from 'styled-components'

export const SupportMail = styled.a`
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 5px;

  svg {
    width: 25px;
  }
`
