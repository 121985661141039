import { useToast } from '@veracity/vui'
import { useState } from 'react'
import {
    CountryStatusModel,
    updateCountryStatus,
} from '../api/countries'
import { getErrorMessage } from '../utils/http-utils'

export const useSendUpdateCountryStatus = () => {
    const [inProgress, setInProgress] = useState(false)
    const [error, setError] = useState<string>()
    const { showSuccess, showError } = useToast()

    const sendUpdateCountryStatusRequest = (status: CountryStatusModel, id: string) => {
        setInProgress(true)
        setError(undefined)

        return updateCountryStatus(id, status)
            .then(() => {
                setInProgress(false)
                showSuccess('Success. Your changes were saved.')
            })
            .catch(err => {
                const errorMessage = getErrorMessage(err)
                showError(errorMessage)
                setError(errorMessage)
                setInProgress(false)
                throw err
            })
    }

    return {
        inProgress,
        error,
        sendUpdateCountryStatusRequest
    }
}
