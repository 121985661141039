import { useEffect, useState } from 'react'
import { CountryResult, getCountries } from '../api/countries'

export const useFetchCountries = () => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<CountryResult[]>()
  const [error, setError] = useState<string>()

  const fetchCountries = () => {
    setInProgress(true)
    setData(undefined)
    setError(undefined)

    return getCountries()
      .then(result => {
        setInProgress(false)
        setData(result)
      })
      .catch(err => {
        setInProgress(false)
          setError(err.response?.data?.message || 'Error')
      })
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  return {
    inProgress,
    countries: data,
    error,
    fetchCountries
  }
}
