import axios from './base'
import { DocumentSectionResult } from './document-sections'

export const getCountryRequirementMatrixDetails = (id: string) =>
  axios
    .get(`/api/CountryRequirementMatrices/${id}`)
    .then(response => response.data as CountryRequirementMatrixDetailsResult)

export interface MatrixDetailsMain {
  countryId: string
  requirementId: string
  technologyId: string
  rfgClassificationTypeId: string
}

export interface MatrixDetailsDocuments {
  areDocumentsApplicable: AreDocumentsApplicable
  areDocumentsApplicableComment: string
  documentSections: string[]
}

export interface MatrixDetailsCompliances {
  complianceLevel: number
  isApplicable: number
  comment: string
  documentSectionId?: string
  complianceMethodId?: string
}

export interface ReadyFlags {
  areDocumentsReadyFlag: boolean
  areCondensedSummariesReadyFlag: boolean
  areKeyCharacteristicsReadyFlag: boolean
  areCompliancesReadyFlag: boolean
}

export enum AreDocumentsApplicable {
  Yes = 0,
  No = 1,
  NotAvailable = 2
}

export const setCountryRequirementSummaries = (
  countryId: string,
  requirementId: string,
  summaries: string[]
) => {
  return axios.put(
    `/api/countries/${countryId}/requirements/${requirementId}/summaries`,
    [...summaries]
  )
}

export const setCountryRequirementMatrixDetails = (
  main: MatrixDetailsMain,
  ReadyFlags: ReadyFlags,
  documents: MatrixDetailsDocuments | null,
  condensedSummaries: string[],
  keyCharacteristics: KeyCharacteristicsModel[],
  compliances: MatrixDetailsCompliances[] | [],
  matrixId: string | null
) => {
  const payload = {
    areDocumentsApplicable:
      documents === null
        ? AreDocumentsApplicable.No
        : documents.areDocumentsApplicable,
    areDocumentsApplicableComment:
      documents === null ? '' : documents.areDocumentsApplicableComment,
    documentSections: documents === null ? [] : documents.documentSections,
    ...ReadyFlags,
    condensedSummary: [...condensedSummaries],
    keyCharacteristics: keyCharacteristics.map(keyCharacteristic => {
      if (keyCharacteristic.id === '0') delete keyCharacteristic.id
      return keyCharacteristic
    }),
    compliances
  }

  if (
    payload.documentSections.length === 0 &&
    payload.areDocumentsApplicable === AreDocumentsApplicable.Yes
  )
    payload.areDocumentsApplicable = AreDocumentsApplicable.No

  const request = matrixId
    ? axios.put(`/api/CountryRequirementMatrices/${matrixId}`, payload)
    : axios.post(`/api/CountryRequirementMatrices`, {
        ...main,
        ...payload
      })

  return request.then(response => {
    return Promise.all(
      payload.keyCharacteristics
        .filter(x => !!x.file)
        .map(x => {
          const formData = new FormData()
          formData.append('keyCharacteristicTypeId', x.keyCharacteristicTypeId)
          formData.append('file', x.file || '')
          return axios.postForm(
            `/api/CountryRequirementMatrices/${
              matrixId || response.data.id
            }/keyCharacteristic`,
            formData
          )
        })
    ).then(() => response)
  })
}

export interface CountryRequirementMatrixDetailsResult {
  id: string
  country: CountryResult
  technology: TechnologyResult
  requirement: RequirementResult
  rfgClassificationType: RfgClassificationResult

  areDocumentsReadyFlag: boolean
  areCondensedSummariesReadyFlag: boolean
  areKeyCharacteristicsReadyFlag: boolean
  areCompliancesReadyFlag: boolean

  areDocumentsApplicable: AreDocumentsApplicable
  areDocumentsApplicableComment: string

  documentSections: DocumentSectionResult[]
  condensedSummaries: string[]
  keyCharacteristics: KeyCharacteristicsResult[]
  compliances: ComplianceResult[]
}

interface DictionaryResult {
  id: string
  name: string
}

interface CountryResult extends DictionaryResult {}

interface TechnologyResult extends DictionaryResult {}

interface RfgClassificationResult extends DictionaryResult {}

interface RequirementResult extends DictionaryResult {
  requirementCategoryId: string
}

export interface KeyCharacteristicsResult {
  id?: string
  plotData?: string
  comment?: string
  keyCharacteristicTypeId: string
}

export interface KeyCharacteristicsModel {
  id?: string
  plotData?: string
  comment?: string
  keyCharacteristicTypeId: string
  file?: File
}

export enum ComplianceLevel {
  Unit = 0,
  Project = 1
}

export enum IsComplianceApplicable {
  Yes = 0,
  No = 1,
  NotAvailable = 2
}

export interface ComplianceResult {
  id: string
  level: ComplianceLevel
  isApplicable: IsComplianceApplicable
  complianceMethod?: ComplianceMethodResult
  documentSection?: DocumentSectionResult
  comment: string
}

export interface ComplianceMethodResult {
  id: string
  name: string
  countryId: string
}

export interface technologyListInt {
  technologyId: string
  technologyName: string
  matrices: { [x: string]: CountryRequirementMatrixDetailsResult }
}
