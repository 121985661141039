import axios from './base'

export const getKeyCharacteristicTypes = (requirementId?: string) =>
  axios
    .get(
      !requirementId
        ? '/api/KeyCharacteristicTypes'
        : `/api/keyCharacteristicTypes?byRequirementId=${requirementId}`
    )
    .then(response => response.data as KeyCharacteristicTypeResult[])

export interface KeyCharacteristicTypeResult {
  id: string
  name: string
  tableDataJsonSchema?: string
}
