import { Box, Heading, Icon, Modal } from '@veracity/vui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { CountryRequirementMatrixDetailsResult } from '../../api/country-requirement-matrixes'
import { SupportMail } from '../../components/styled/supportMail'

import { RootState } from '../../store'
import { DictionaryEntry } from '../../store/slices/dictionaries'
import { CloneControlsUi } from './CloneControlsUi'
import { TabsDataUiBinder } from './TabsDataUiBinder/TabsDataUiBinder'

interface inModalDataInt {
  requirement: string
  technology: string
  idsArr: string[]
}

export const ModalForPreviewAndEdit = (props: any) => {
  const { showError, showSuccess } = props

  const [isOpen, setIsOpen] = props.modalState

  const { requirement, technology, idsArr }: inModalDataInt =
    props.matricesData.inModalData

  const [
    countryId,
    requirementId,
    technologyId,
    rfgClassificationTypeId,
    matrixId
  ] = idsArr

  const mainIds = {
    countryId,
    requirementId,
    technologyId,
    rfgClassificationTypeId
  }
  const [matricesData, setMatricesData] =
    useState<CountryRequirementMatrixDetailsResult>()

  const [activeTab, setActiveTab] = useState<number>(0)

  useEffect(() => {
    const matricesData: CountryRequirementMatrixDetailsResult =
      props.matricesData.map
        .get(requirementId)
        ?.find(
          (elem: { technologyId: string }) => elem.technologyId === technologyId
        )?.matrices[rfgClassificationTypeId]

    setMatricesData(matricesData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateData = (matricesData: CountryRequirementMatrixDetailsResult) => {
    if (matricesData && matricesData?.rfgClassificationType?.id) {
      let updatedMapRecord = props.matricesData.map.get(
        matricesData.requirement.id
      )
      const updatedMapTechId = updatedMapRecord
        .map((elem: { technologyId: string }) => elem.technologyId)
        .indexOf(matricesData.technology.id)

      const currentlyChangeMatrix =
        updatedMapRecord[updatedMapTechId].matrices[
          matricesData.rfgClassificationType.id
        ]

      props.matricesData.setMap({
        ...currentlyChangeMatrix,
        ...matricesData
      })
    }
  }

  useEffect(() => {
    let updatedMapRecord = props.matricesData.map.get(requirementId)

    const updatedMapTechId = updatedMapRecord
      .map((elem: { technologyId: string }) => elem.technologyId)
      .indexOf(technologyId)

    const currentlyChangeMatrix =
      updatedMapRecord[updatedMapTechId].matrices[rfgClassificationTypeId]

    setMatricesData(currentlyChangeMatrix)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matricesData.map])
  //
  const rfgClassificationTypes = useSelector<RootState, DictionaryEntry[]>(
    state => state.dictionaries.rfgClassificationTypes
  )
  //
  // const [rfgType, setRfgType] = useState(rfgClassificationTypeId);
  const rfgTypeName =
    rfgClassificationTypes.find(item => item.id === rfgClassificationTypeId)
      ?.name || ''
  //
  const activeTabName = [
    'document',
    'summaries',
    'key characteristics',
    'compliance'
  ][activeTab]

  return (
    <Modal
      disableBackdropClose
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}>
      <Box
        bg="white"
        borderRadius="md"
        boxShadow="2"
        centerH
        column
        left="50%"
        outline="none"
        p={2}
        pb={4}
        position="absolute"
        top="8vh"
        transform="translate(-50%,0)"
        minW="80vw"
        minH="80vh"
        maxH="88vh"
        alignItems="flex-start"
        data-id={matrixId}>
        <Box
          position="absolute"
          right={-8}
          top={-8}
          borderRadius={20}
          borderStyle={'solid'}
          borderColor={'white'}
          bg="white"
          borderWidth={5}
          cursor="pointer"
          onClick={() => setIsOpen(false)}>
          <Icon name="falTimesCircle" size="sm" />
        </Box>

        <Heading size="h2">{requirement}</Heading>

        <CloneControlsUi
          technology={technology}
          technologyId={technologyId}
          rfgTypeName={rfgTypeName}
          rfgClassificationTypeId={rfgClassificationTypeId}
          matricesDataSetter={updateData}
          technologyList={props.matricesData.map.get(requirementId)}
          mainIds={mainIds}
          currentId={matrixId}
          showError={errorText => {
            showError(errorText)
            setActiveTab(0)
          }}
          showSuccess={successText => {
            showSuccess(successText)
            setActiveTab(0)
          }}
        />

        {matricesData && (
          <TabsDataUiBinder
            data={matricesData}
            mainIds={mainIds}
            matrixId={matrixId}
            updateData={updateData}
            showError={showError}
            showSuccess={showSuccess}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        <SupportMail
          href={`mailto:digcce@dnv.com?subject=${encodeURIComponent(
            'Question from DiGCCe - Back-Office User'
          )}&body=${encodeURIComponent(
            `In 
  Country:           ${props.matricesData.countryName}, 
  Requirement:  ${requirement}, 
  Technology:     ${technology}, 
  RfG Classification Type: ${rfgTypeName}
  on: ${activeTabName} tab\n\n`
          )}`}>
          Support <Icon name="falCommentAlt" size="sm" />
        </SupportMail>
      </Box>
    </Modal>
  )
}
