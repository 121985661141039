import { Box, Button, Icon, styled } from '@veracity/vui'

import { CountryDetailsResult, DraftStatus } from '../../api/countries'
import { ModalDialog } from '../../components/ModalDialog'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import './CountryPreviewModal.css'

export interface CountryPreviewModalProps {
  isOpen: boolean
  country: CountryDetailsResult | undefined
  onClose: () => void
  onReviewDraft: (id: string, status: DraftStatus) => void
}

export const CountryPreviewModal = ({
  country,
  isOpen,
  onClose,
  onReviewDraft
}: CountryPreviewModalProps) => {
  const BoxLabel = styled.div`
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    width: 100%;
  `

  const handleReviewDraft = (id: string, isAccepted: boolean) => {
    const status = isAccepted ? DraftStatus.Approved : DraftStatus.Rejected
    onReviewDraft(id, status)
  }

  return (
    <ModalDialog isOpen={isOpen} onClose={onClose} title="Preview">
      {!!country && (
        <Box row className="displayedText" w="100%">
          <Box center column w="100%" h="100%">
            <BoxLabel>Name</BoxLabel>
            <Box row w="100%" mb={1} border="1px dashed #8799a7" p={2}>
              {country.name}
            </Box>
            <BoxLabel>ISO code</BoxLabel>
            <Box row w="100%" border="1px dashed #8799a7" mb={1} p={2}>
              <ReactMarkdown>{country.isoCode}</ReactMarkdown>
            </Box>
            <BoxLabel>Country intro</BoxLabel>
            <Box row w="100%" border="1px dashed #8799a7" mb={1} p={2}>
              <ReactMarkdown className="markdown">
                {country.intro}
              </ReactMarkdown>
            </Box>
            <BoxLabel>Country info</BoxLabel>
            <Box row w="100%" border="1px dashed #8799a7" mb={1} p={2}>
              <ReactMarkdown
                children={country.info}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                className="textStyle"
              />
            </Box>
            <BoxLabel>Result note</BoxLabel>
            <Box row w="100%" border="1px dashed #8799a7" mb={1} p={2}>
              <ReactMarkdown
                children={country.resultsNote}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                className="textStyle"
              />
            </Box>
          </Box>
          <Box row justifyContent="flex-end" mt={2}>
            <Button
              variant="solidGreen"
              mr={3}
              onClick={() => handleReviewDraft(country.id, true)}>
              <Icon name={'falCheck'} size="sm" />
              Accept
            </Button>
            <Button
              variant="solidRed"
              onClick={() => handleReviewDraft(country.id, false)}>
              <Icon name={'falTimes'} size="sm" />
              Reject
            </Button>
          </Box>
        </Box>
      )}
    </ModalDialog>
  )
}
