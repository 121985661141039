export const CONFIGURATION = {
  URLS: {
    DOCUMENT: '/files/documents',
    KEY_CHARACTERISTICS: '/files/keyCharacteristics'
  },
  SEED_DATA: {
    FILE_TYPE:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
}
