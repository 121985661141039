import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { UserRoles } from '../api/users'
import { RootState } from '../store'

export const ProtectedRoute = ({
  level,
  children
}: {
  level?: number
  children: ReactElement
}) => {
  const isLoggedAs: UserRoles = useSelector<RootState, UserRoles>(
    state => UserRoles[state.user.role as keyof typeof UserRoles]
  )
  if (
    isLoggedAs === UserRoles.Viewer ||
    isLoggedAs === UserRoles.Unknown ||
    isLoggedAs === undefined
  ) {
    return <Navigate to="/" />
  } else if (level && isLoggedAs !== level) {
    return <Navigate to="/countries" />
  }

  return children
}
