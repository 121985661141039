import { Box, P, ButtonToggleGroup, Button, Textarea } from '@veracity/vui'
import { AreDocumentsApplicable } from '../../../api/country-requirement-matrixes'

import { TreeSelect } from 'antd'
import { DocumentsSectionTreeOptions } from './TabsDataUiBinder'

interface DocumentsTabInt {
  countryDocumentsSectionTree: DocumentsSectionTreeOptions[]

  areDocumentsApplicable: AreDocumentsApplicable
  setAreDocumentsApplicable: (x: AreDocumentsApplicable) => void

  areDocumentsApplicableComment: string
  setAreDocumentsApplicableComment: (x: string) => void

  documentSectionsSelected: string[]
  setDocumentSectionsSelected: (x: string[]) => void

  errorsAreShown: boolean
}

const DocumentsTabUI = ({
  countryDocumentsSectionTree,
  areDocumentsApplicable,
  setAreDocumentsApplicable,
  areDocumentsApplicableComment,
  setAreDocumentsApplicableComment,
  documentSectionsSelected,
  setDocumentSectionsSelected,

  errorsAreShown
}: DocumentsTabInt) => {
  const onChangeDocumentSections = (newValue: string[]) => {
    setDocumentSectionsSelected(newValue)
  }

  return (
    <Box p={0} column>
      <P size="sm" mt={1}>
        Documents are:{' '}
      </P>
      <ButtonToggleGroup>
        <Button
          isActive={areDocumentsApplicable === AreDocumentsApplicable.Yes}
          onClick={() => setAreDocumentsApplicable(AreDocumentsApplicable.Yes)}>
          Applicable
        </Button>

        <Button
          isActive={areDocumentsApplicable === AreDocumentsApplicable.No}
          onClick={() => setAreDocumentsApplicable(AreDocumentsApplicable.No)}>
          Not Applicable
        </Button>

        <Button
          isActive={
            areDocumentsApplicable === AreDocumentsApplicable.NotAvailable
          }
          onClick={() =>
            setAreDocumentsApplicable(AreDocumentsApplicable.NotAvailable)
          }>
          Not Available
        </Button>
      </ButtonToggleGroup>

      {areDocumentsApplicable === AreDocumentsApplicable.NotAvailable && (
        <Textarea
          maxH={32}
          mt={1}
          colorScheme={
            errorsAreShown &&
            ((areDocumentsApplicableComment || '') + '').trim().length <= 3
              ? 'red'
              : undefined
          }
          value={areDocumentsApplicableComment || ''}
          onBlur={e => setAreDocumentsApplicableComment(e.target.value)}
        />
      )}

      {areDocumentsApplicable === AreDocumentsApplicable.Yes && (
        <>
          <P size="sm" mt={1}>
            Documents sections*:{' '}
          </P>

          <TreeSelect
            multiple={true}
            showSearch={false}
            style={{ width: '100%' }}
            status={
              errorsAreShown && documentSectionsSelected.length === 0
                ? 'error'
                : undefined
            }
            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: '2000' }}
            placeholder="Please select documents"
            allowClear
            onChange={onChangeDocumentSections}
            treeData={countryDocumentsSectionTree}
            value={documentSectionsSelected}
          />
        </>
      )}
    </Box>
  )
}

export default DocumentsTabUI
