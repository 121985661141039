import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BaseTable } from '../../../components/Table'

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, Button, Icon, Box } from '@veracity/vui'

interface KeyCharacteristicDataSchema {
  id: string
  label: string
  defaultValue: string
  populationHint: string
}

interface TableWrapperProps {
  schema: string
  data: string
  sendDataUp: (arg0: any[]) => void
}

export const TableWrapper = (props: TableWrapperProps) => {
  const schema: KeyCharacteristicDataSchema[] = JSON.parse(
    props?.schema || '[]'
  )
  const dataSource = JSON.parse(props?.data || '[]')
  //
  const columnWidth = Math.round((100 - 8) / schema.length)
  const columns = schema.map(({ id, label, populationHint }) => {
    const tooltip = populationHint
      ? {
          filterIcon: populationHint ? (
            <Icon name="falInfoCircle" size="sm" />
          ) : null,

          filterDropdown: () => (
            <Box bg="darkBlue.95" p={1} maxW="20em">
              {populationHint}
            </Box>
          )
        }
      : {}

    return {
      title: label,
      dataIndex: id,
      key: id,
      formControlName: id,
      editable: true,
      width: `${columnWidth}%`,
      ...tooltip
    }
  })

  const _columnsInData = schema.map(x => x.id)

  const reIndexArrKeys = (dataArr: any[]) => {
    return dataArr.map((item: any, index: any) => {
      return { ...item, key: index + 1 }
    })
  }

  const latsRecordEdited = useRef<any>()
  let _dataSource = dataSource
  try {
    _dataSource = dataSource.plotData
      ? JSON.parse(dataSource.plotData)
      : dataSource

    if (typeof _dataSource === 'object' && !Array.isArray(_dataSource)) {
      _dataSource = [{ key: 1, ..._dataSource }]
    }
    if (Array.isArray(_dataSource)) _dataSource = reIndexArrKeys(_dataSource)
  } catch (err) {
    _dataSource = []
  }

  const _validationYopSchema = Object.fromEntries(
    _columnsInData.map((col: string) => {
      return [col, yup.string()]
    })
  )

  const { control, setValue, getValues } = useForm<any>({
    resolver: yupResolver(yup.object(_validationYopSchema)),
    defaultValues: Object.fromEntries(
      _columnsInData.map(item => {
        return [item, '']
      })
    )
  })

  const handleEditRowClick = (record: { key: number }) => {
    setSelectedToEditId(record.key)
    const recordCellsArr = Object.entries(record)

    recordCellsArr.forEach(item => {
      setValue(item[0], item[1])
    })
  }

  const handleEditRowSave = (record: { key: string; plotData?: string }) => {
    let newData = [..._dataSource]

    const index = _dataSource.findIndex(
      ({ key }: { key: string }) => key === record.key
    )

    newData[index] = getValues()
    props.sendDataUp([...newData])
    //
    handleClose()
  }

  const resetFormValues = () => {
    schema.forEach(
      ({ id, defaultValue }: { id: string; defaultValue?: string }) => {
        setValue(id, defaultValue || '')
      }
    )
  }

  const handleClose = () => {
    latsRecordEdited.current = null
    setSelectedToEditId(0)
    resetFormValues()
  }

  const handleRowRemove = (record: { key: number }) => {
    const newData = [..._dataSource]
    const index = record.key - 1

    if (index !== -1) {
      newData.splice(index, 1)
      props.sendDataUp(reIndexArrKeys(newData))
    }
    //
    handleClose()
  }

  const addNewRow = () => {
    const _newData = Object.fromEntries(
      schema.map(
        ({ id, defaultValue }: { id: string; defaultValue?: string }) => [
          id,
          defaultValue || ''
        ]
      )
    )
    _newData.key = _dataSource.length + 1

    props.sendDataUp([...reIndexArrKeys(_dataSource), _newData])
    setSelectedToEditId(_dataSource.length + 1)
    resetFormValues()
  }

  const editableColumn = {
    title: '',
    dataIndex: 'operation',
    width: '8%',
    editable: false,
    hint: undefined,
    render: (_: any, record: { key: any }) => {
      const editable = isRowInEdit(record)
      return editable ? (
        <span>
          <IconButton
            icon="falSave"
            size="sm"
            onClick={() => {
              handleEditRowSave(record)
            }}
          />

          <IconButton
            icon="falTimesCircle"
            size="sm"
            onClick={() => handleClose()}
          />
        </span>
      ) : (
        <span>
          <IconButton
            icon="falPen"
            size="sm"
            onClick={() => handleEditRowClick(record)}
          />

          <IconButton
            icon="falTrashAlt"
            size="sm"
            onClick={() => handleRowRemove(record)}
          />
        </span>
      )
    }
  }

  const [selectedToEditId, setSelectedToEditId] = useState<number>(0)

  const isRowInEdit = (rowData: { key: any }) => {
    if (!selectedToEditId || !rowData.key) return false
    return rowData.key === selectedToEditId
    // return false
  }

  const mergedColumns = [...columns, editableColumn].map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isRowInEdit(record)
      })
    }
  })

  return (
    <>
      <BaseTable
        columns={mergedColumns}
        dataSource={_dataSource}
        pagination={false}
        tableLayout={'fixed'}
        size={'small'}
        rowClassName="editable-row"
        isRowInEdit={isRowInEdit}
        control={control}
      />

      <Button
        size="sm"
        alignSelf={'end'}
        m={1}
        variant="secondaryDark"
        onClick={addNewRow}>
        add new row
      </Button>
    </>
  )
}
