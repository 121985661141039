import {
  Box,
  Accordion,
  Heading,
  Textarea,
  Spinner,
  IconButton,
  useToast
} from '@veracity/vui'
import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCountryRequirementsMatrices } from '../../api/countries'

import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { ModalForPreviewAndEdit } from './ModalForPreviewAndEdit'

import { dictionariesActions } from '../../store/slices/dictionaries'

import { useFetchCountry } from '../../hooks/useFetchCountry'
import { setCountryRequirementSummaries } from '../../api/country-requirement-matrixes'
import TechnologiesTable from './TechnologiesTable'

interface RequirementCategoryResult {
  requirementCategoryId: string
  requirementCategoryName: string
  requirements: RequirementsListResult[]
}
interface RequirementsListResult {
  requirementId: string
  requirementName: string
  rfgClassificationTypes: object[]
  technologies: object[]
  countryRequirementSummaries: string[]
}
//

interface requirementCategory {
  id: string
  name: string
  requirements: RequirementsListElement[]
}

export interface RequirementsListElement {
  id: string
  name: string
  summaries: string[]
}

export const CountryRequirements = () => {
  const { countryId } = useParams()
  const { showError, showSuccess } = useToast()

  const { inProgress: fetchInProgress, country } = useFetchCountry(countryId)

  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [inModalData, setInModalData] = useState({
    requirement: '',
    technology: '',
    idsArr: ['', '', '', '']
  })

  const [technologyMap, setTechnologyMap] = useState(new Map())

  const [technologyMapLoaded, setTechnologyMapLoaded] = useState(false)
  const [requirementCatagoriesArr, setRequirementCategoriesArr] = useState<
    requirementCategory[]
  >([])

  useEffect(() => {
    if (countryId !== undefined)
      getCountryRequirementsMatrices(countryId).then(result => {
        if (result.rfgClassificationTypes)
          dispatch(
            dictionariesActions.setClassificationTypes(
              result.rfgClassificationTypes
            )
          )

        const _technologyMap = new Map()
        const _requirementCategoriesArr = result.data.map(
          ({
            requirementCategoryId,
            requirementCategoryName,
            requirements
          }: RequirementCategoryResult) => {
            const _requirements = requirements.map(requirement => {
              _technologyMap.set(
                requirement.requirementId,
                requirement.technologies
              )
              return {
                id: requirement.requirementId,
                name: requirement.requirementName,
                summaries: requirement.countryRequirementSummaries
              }
            })

            return {
              id: requirementCategoryId,
              name: requirementCategoryName,
              requirements: _requirements
            }
          }
        )

        setTechnologyMap(_technologyMap)
        setRequirementCategoriesArr(_requirementCategoriesArr)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (requirementCatagoriesArr.length > 0) setTechnologyMapLoaded(true)
  }, [requirementCatagoriesArr])

  const [collapsed, setCollapsed] = useState<string[]>([])

  const handleUpdateRecord = (metric: {
    requirementId: string
    technologyId: string
    rfgClassificationTypeId: string
  }) => {
    const _currentTechMap = technologyMap.get(metric.requirementId)
    const updatedMapTechId = _currentTechMap
      .map((elem: { technologyId: string }) => elem.technologyId)
      .indexOf(metric.technologyId)
    _currentTechMap[updatedMapTechId].matrices[metric.rfgClassificationTypeId] =
      metric

    technologyMap.set(metric.requirementId, _currentTechMap)

    setTechnologyMap(new Map(technologyMap))
  }

  const summariesTextAreaRef = useRef(null)
  const handleSaveSummaries = (requirementId: string) => {
    const _textAreaCon = summariesTextAreaRef?.current as HTMLElement | null

    if (!countryId || !_textAreaCon || _textAreaCon?.firstElementChild === null)
      return false

    const _textArea = _textAreaCon.firstElementChild as HTMLTextAreaElement
    setCountryRequirementSummaries(countryId, requirementId, [_textArea.value])
      .then(() => {
        showSuccess('Success Your Summaries changes were saved.')
      })
      .catch(error => {
        const { message } = error.response.data
        showError(
          message ||
            "Error. Something went wrong - we couldn't save your changes. Please try later."
        )
      })
  }

  if (
    fetchInProgress ||
    (!technologyMapLoaded &&
      requirementCatagoriesArr.length < 2 &&
      !technologyMap)
  )
    return (
      <Spinner text="Loading technology's..." m={10} ml={'auto'} mr={'auto'} />
    )

  const countryName = country?.name
  return (
    <Box column minW="100%">
      <PageBreadcrumbs
        items={[
          { label: 'Countries', to: '/countries' },
          { label: `${countryName} Requirements` }
        ]}
      />
      <Heading size="lead" p={1}>
        {countryName}
      </Heading>
      <Accordion minW="100%">
        {requirementCatagoriesArr.map(({ id, name, requirements }) => (
          <Accordion.Item key={id} id={id} title={name} collapsed>
            <Accordion minW="100%">
              {requirements.map(requirement => (
                <Accordion.Item
                  key={requirement.id}
                  id={requirement.id}
                  title={requirement.name}
                  onToggle={() => {
                    const isCollapsed = collapsed.includes(requirement.id)
                    setCollapsed(
                      isCollapsed
                        ? collapsed.filter(id => id !== requirement.id)
                        : [...collapsed, requirement.id]
                    )
                  }}
                  collapsed>
                  <Box column minW="100%" position="relative">
                    <Textarea
                      value={requirement.summaries[0] || ''}
                      ref={summariesTextAreaRef}></Textarea>

                    <IconButton
                      icon="falSave"
                      size="sm"
                      title="Save description"
                      position="absolute"
                      top={0}
                      right={-24}
                      onClick={() => handleSaveSummaries(requirement.id)}
                    />

                    {collapsed.includes(requirement.id) && countryId && (
                      <TechnologiesTable
                        technologyArr={technologyMap.get(requirement.id)}
                        countryId={countryId}
                        requirement={requirement}
                        setInModalData={setInModalData}
                        setIsModalOpen={setIsModalOpen}
                      />
                    )}
                  </Box>
                </Accordion.Item>
              ))}
            </Accordion>
          </Accordion.Item>
        ))}
      </Accordion>

      {isModalOpen && technologyMap && technologyMap.size > 0 && (
        <ModalForPreviewAndEdit
          showError={showError}
          showSuccess={showSuccess}
          modalState={[isModalOpen, setIsModalOpen]}
          matricesData={{
            map: technologyMap,
            setMap: handleUpdateRecord,
            inModalData: inModalData,
            countryName: countryName
          }}></ModalForPreviewAndEdit>
      )}
    </Box>
  )
}
