import { Breadcrumbs } from '@veracity/vui'
import { Link } from 'react-router-dom'

interface PageBreadcrumbItem {
  label: string
  to?: string
}

export interface PageBreadcrumbsProps {
  items?: PageBreadcrumbItem[]
}

export const PageBreadcrumbs = ({ items }: PageBreadcrumbsProps) => (
  <Breadcrumbs pt={2} pb={2}>
    <Breadcrumbs.Item key="-1" as={Link} to="/">
      Home
    </Breadcrumbs.Item>
    {items?.map((item, index) =>
      !!item.to ? (
        <Breadcrumbs.Item key={index} as={Link} to={item.to}>
          {item.label}
        </Breadcrumbs.Item>
      ) : (
        <Breadcrumbs.Item key={index}>{item.label}</Breadcrumbs.Item>
      )
    )}
  </Breadcrumbs>
)
