import { DictionaryEntry } from '../store/slices/dictionaries'
import axios from './base'

export interface RfgClassificationType extends DictionaryEntry {
  description: string
}

export const getRfgClassificationTypes = () =>
  axios
    .get('/api/rfgClassificationTypes')
    .then(response => response.data as RfgClassificationType[])

export const getCountryRequirements = (countryId: string) =>
  axios
    .get(`/api/countries/${countryId}/rfgClassificationTypes`)
    .then(response => response.data)

export const setCountryRequirements = (
  countryId: string,
  rfgClassificationTypes: RfgClassificationType[]
) =>
  axios.put(`/api/countries/${countryId}/rfgClassificationTypes`, {
    items: rfgClassificationTypes.map(({ id, name, description }) => {
      return { name, description, rfgClassificationTypeId: id }
    })
  })
