import axios from './base'
import { DocumentResult } from './documents'

export interface DocumentStatusModel {
  isPublished: boolean
  documentId: string
}

export const updateCountryDocumentStatus = (
  countryId: string,
  status: DocumentStatusModel
) => axios.patch(`/api/Countries/${countryId}/Documents`, status)

export const getCountryDocuments = (countryId: string) =>
  axios
    .get(`/api/Countries/${countryId}/Documents`)
    .then(response => response.data as DocumentResult[])

export const updateCountryDocuments = (
  countryId: string,
  documents: string[]
) => {
  return axios.put(`/api/Countries/${countryId}/Documents`, documents)
}
