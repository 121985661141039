import axios from './base'

export interface CountryResult {
  id: string
  name: string
  isPublished: boolean
  hasChanges: boolean
}

export const getCountries = () =>
  axios.get('/api/countries').then(response => response.data as CountryResult[])

export interface CountryDetailsResult {
  id: string
  name: string
  isoCode: string
  info: string
  intro: string
  resultsNote: string
}

export enum DraftStatus{
  Draft = 1,
  Approved = 2,
  Rejected = 3
}

export interface DraftStatusModel {
  status: DraftStatus
}

export interface CountryStatusModel {
  isActive: boolean
}

export const getCountry = (id: string) =>
  axios
    .get(`/api/countries/${id}`)
    .then(response => response.data as CountryDetailsResult)

export const getCountryDraft = (id: string) =>
  axios
    .get(`/api/countries/draft/${id}`)
    .then(response => response.data as CountryDetailsResult)

export interface CountryModel {
  name: string
  isoCode: string
  info: string
  intro: string
  resultsNote: string
}

export interface CountryEditModel {}
export interface CountryCreateModel {}

export const createCountry = (country: CountryCreateModel) =>
  axios.post(`/api/countries`, country)

export const updateCountry = (id: string, country: CountryEditModel) =>
  axios.put(`/api/countries/${id}`, country)

export const changeCountryDraftStatus = (id: string, status: DraftStatusModel) =>
  axios.patch(`/api/countries/${id}`, status)

export const getCountryRequirementsMatrices = (id: string) =>
  axios
    .get(`/api/CountryRequirementMatrices?countryId=${id}`)
    .then(response => response.data)

export const updateCountryStatus = (id: string, status: CountryStatusModel) =>
  axios.patch(`/api/countries/status/${id}`, status)
