import { useEffect, useState } from 'react'
import { getCountryDocuments } from '../api/country-documents'
import { DocumentResult } from '../api/documents'

export const useFetchCountryDocuments = (id?: string) => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<DocumentResult[] | undefined>()
  const [error, setError] = useState<string>()

  const fetchCountryDocuments = (countryId: string) => {
    setData(undefined)
    setError(undefined)
    setInProgress(true)
    getCountryDocuments(countryId)
      .then((data: DocumentResult[] | undefined) => {
        setInProgress(false)
        setData(data)
      })
      .catch((err: { message: string | undefined }) => {
        setInProgress(false)
        setError(err.message)
      })
  }

  useEffect(() => {
    if (id) fetchCountryDocuments(id)
  }, [id])

  return {
    inProgress,
    documents: data,
    error,
    fetchCountryDocuments
  }
}
