import axios from './base'

export interface DocumentSectionResult {
  id: string
  name: string
  documentShortName: string
  documentId?: string
  page?: number
  description?: string
}

export const getDocumentSections = (byDocumentId?: string) =>
  axios
    .get(
      byDocumentId
        ? `/api/documentSections?byDocumentId=${byDocumentId}`
        : '/api/documentSections'
    )
    .then(response => response.data as DocumentSectionResult[])

export interface DocumentSectionDetailsResult {
  id: string
  name: string
  page: number
  description: string
  documentId: string
}

export const getDocumentSectionById = (id: string) =>
  axios
    .get(`/api/documentSections/${id}`)
    .then(response => response.data as DocumentSectionDetailsResult)

export interface DocumentSectionModel {
  name: string
  documentId: string
  page?: number
  description?: string
}

export const updateDocumentSection = (
  id: string,
  model: DocumentSectionModel
) => axios.put(`/api/documentSections/${id}`, model)

export const createDocumentSection = (model: DocumentSectionModel) =>
  axios.post('/api/documentSections', model)

export interface DocumentSectionGroupedResult {
  id: string
  name: string
  shortName: string
  documentSections: DocumentSectionDetailsResult[]
}

export const getDocumentSectionsGrouped = () =>
  axios
    .get('/api/DocumentSections/grouped')
    .then(response => response.data as DocumentSectionGroupedResult[])

export const getCountryDocumentsSections = (countryId: string) =>
  axios
    .get(`/api/Countries/${countryId}/Documents/Sections`)
    .then(response => response.data as DocumentSectionGroupedResult[])
