import dayjs from 'dayjs'
import axios from './base'
import { convertObjectToFormData } from '../utils/http-utils'

export enum DocumentType {
  GridCodeRequirements = 0,
  GuidelinesAndProcesses = 1,
  Other = 2
}

export enum DocumentStatus {
  Draft = 0,
  Active = 1,
  Inactive = 2
}

export enum DocumentLanguage {
  Official = 0,
  UnOfficial = 1,
  Native = 2
}

export enum DocumentAvailability {
  Public = 0,
  Bought = 1
}

export interface DocumentResult {
  id: string
  name: string
  shortName: string
  documentType: DocumentType
  officialUrl: string
  status: DocumentStatus
  language: DocumentLanguage
  availability: DocumentAvailability
  author: string
  releaseDate: Date
  comment: string
  keywords: string[]
  isPublished: boolean
}

export const getDocuments = () =>
  axios
    .get(`/api/documents`)
    .then(response => response.data as DocumentResult[])

export const getDocument = (documentId: string) =>
  axios
    .get(`/api/documents/${documentId}`)
    .then(response => response.data as DocumentResult)

export interface DocumentModel {
  name: string
  shortName: string
  type: DocumentType
  file?: File
  officialUrl: string
  status: DocumentStatus
  language: DocumentLanguage
  availability: DocumentAvailability
  author: string
  releaseDate: Date
  comment: string
  keywords: string[]
}

export const createDocument = (document: DocumentModel) =>
  axios
    .postForm(`/api/documents`, convertDocumentResultToFormData(document))
    .then(response => response.data as { id: string })

export const updateDocument = async (id: string, document: DocumentModel) =>
  axios.putForm(
    `/api/documents/${id}`,
    convertDocumentResultToFormData(document)
  )

const convertDocumentResultToFormData = (document: DocumentModel) =>
  convertObjectToFormData({
    ...document,
    releaseDate: `${dayjs(document.releaseDate).format('YYYY-MM')}-01T00:00:00.000Z`
  })
