import { useMemo } from 'react'
import {
  Box,
  Button,
  Divider,
  DragAndDrop,
  Icon,
  IconButton,
  P,
  Select,
  SelectOptionData,
  T,
  Textarea,
  Image
} from '@veracity/vui'
import {
  KeyCharacteristicsModel,
  KeyCharacteristicsResult
} from '../../../api/country-requirement-matrixes'
import { KeyCharacteristicTypeResult } from '../../../api/key-characteristics-types'
import { TableWrapper } from './KeyCharacteristicsTableWrapper'
import { ifIsValidJsonStr } from '../../../utils/json-utils'
import { KeyCharacteristicPreview } from '../../CountryRequirementMatrixDetails/KeyCharacteristicsInfo/KeyCharacteristicPreview'
import { CONFIGURATION } from '../../../configuration'
import { ImageValidator } from '../../../components/ImageValidator'

interface KeyCharacteristicsTabInt {
  keyCharacteristics: KeyCharacteristicsModel[]
  setKeyCharacteristics: (x: KeyCharacteristicsResult[]) => void
  keyCharacteristicTypes?: KeyCharacteristicTypeResult[]
  errorsAreShown: boolean
}

const KeyCharacteristicsTabUI = ({
  keyCharacteristics,
  setKeyCharacteristics,
  errorsAreShown,
  keyCharacteristicTypes
}: KeyCharacteristicsTabInt) => {
  const keyCharacteristicTypeOptions =
    keyCharacteristicTypes?.map(
      keyCharacteristicType =>
        ({
          value: keyCharacteristicType.id,
          text: keyCharacteristicType.name
        } as SelectOptionData)
    ) || []

  const _keyCharacteristics = useMemo(() => {
    return keyCharacteristics.map((item, index) => {
      const _dict = keyCharacteristicTypes?.find(
        dict => dict.id === item.keyCharacteristicTypeId
      )

      return { ...item, ..._dict, id: index, keyCharacteristicId: item.id }
    })
  }, [keyCharacteristics, keyCharacteristicTypes])

  const setKeyCharacteristicTable = (data: any[], bindId: number) => {
    let _keyCharacteristicIndex = _keyCharacteristics.findIndex(
      item => item.id === bindId
    )
    let _keyCharacteristic = keyCharacteristics[_keyCharacteristicIndex]
    if (
      _keyCharacteristic &&
      _keyCharacteristics[_keyCharacteristicIndex].tableDataJsonSchema !== ''
    ) {
      _keyCharacteristic.plotData = JSON.stringify(data)
      _keyCharacteristic.file = undefined
    }
    setKeyCharacteristics([...keyCharacteristics])
  }
  const setKeyCharacteristic = (
    elem: string,
    index: number = -1,
    value: any = ''
  ) => {
    let _keyCharacteristics = keyCharacteristics
    let _keyCharacteristic: KeyCharacteristicsModel = {
      id: '0',
      keyCharacteristicTypeId: ''
    }

    if (index >= 0) {
      if (keyCharacteristics[index])
        _keyCharacteristic = keyCharacteristics[index]
      if (elem === 'typeId') _keyCharacteristic.keyCharacteristicTypeId = value
      if (elem === 'file') _keyCharacteristic.file = value
      if (elem === 'desc') _keyCharacteristic.comment = value
      //
      _keyCharacteristics[index] = _keyCharacteristic
    }
    if (elem === 'new') {
      _keyCharacteristics = [..._keyCharacteristics, _keyCharacteristic]
    }

    setKeyCharacteristics([..._keyCharacteristics])
  }

  const removeKeyCharacteristic = (index: number) => {
    keyCharacteristics.splice(index, 1)
    setKeyCharacteristics([...keyCharacteristics])
  }

  return (
    <Box p={1} column maxH={'calc(88vh - 240px)'} overflow="auto">
      {_keyCharacteristics.map(
        (
          {
            id,
            keyCharacteristicTypeId,
            comment,
            file,
            plotData,
            tableDataJsonSchema,
            keyCharacteristicId
          },
          index
        ) => (
          <Box
            key={id}
            data-id={id}
            mt={3}
            direction={'column'}
            position="relative">
            {index > 0 && <Divider m={2} />}

            <Box
              position="absolute"
              right={5}
              top={-3}
              bg="white"
              p={1}
              pb={0}
              cursor="pointer"
              onClick={() => {
                removeKeyCharacteristic(index)
              }}>
              <Icon name="falTrashAlt" size="xs" />
            </Box>

            <P size="sm" mt={1}>
              Type*:{' '}
            </P>
            <Select
              options={keyCharacteristicTypeOptions}
              onChange={e => {
                setKeyCharacteristic('typeId', index, e.target.value)
              }}
              isInvalid={errorsAreShown && keyCharacteristicTypeId.length < 3}
              value={keyCharacteristicTypeId}></Select>
            {!tableDataJsonSchema && (
              <>
                <P size="sm" mt={1}>
                  File*:{' '}
                </P>
                <DragAndDrop
                  accept="image/png"
                  onFilesAdded={([file]) =>
                    setKeyCharacteristic('file', index, file)
                  }
                />
                {!!file ? (
                  <Box p={1} justifyContent="space-between">
                    <T>{file?.name}</T>

                    <IconButton
                      icon="falTrashAlt"
                      onClick={() =>
                        setKeyCharacteristic('file', index, undefined)
                      }
                    />
                  </Box>
                ) : (
                  <>
                    {keyCharacteristicId !== '0' && (
                      <ImageValidator
                        imageSrc={`${CONFIGURATION.URLS.KEY_CHARACTERISTICS}/${keyCharacteristicId}`}>
                        <KeyCharacteristicPreview>
                          <Image
                            src={`${CONFIGURATION.URLS.KEY_CHARACTERISTICS}/${keyCharacteristicId}`}
                          />
                        </KeyCharacteristicPreview>
                      </ImageValidator>
                    )}
                  </>
                )}
              </>
            )}
            <P size="sm" mt={1}>
              Description:{' '}
            </P>
            <Textarea
              value={comment || ''}
              onChange={e => {
                setKeyCharacteristic('desc', index, e.target.value)
              }}
            />
            {tableDataJsonSchema && tableDataJsonSchema !== '' && (
              <TableWrapper
                schema={ifIsValidJsonStr(tableDataJsonSchema, '[]')}
                sendDataUp={data => setKeyCharacteristicTable(data, id)}
                data={ifIsValidJsonStr(plotData || '[]')}
              />
            )}
          </Box>
        )
      )}
      <Button
        size="sm"
        alignSelf={'end'}
        m={1}
        variant="secondaryDark"
        onClick={() => {
          setKeyCharacteristic('new')
        }}>
        add key characteristic
      </Button>
    </Box>
  )
}

export default KeyCharacteristicsTabUI
