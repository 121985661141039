import { RouteObject } from 'react-router-dom'
import HomePage from './pages/Home'
import NotFoundPage from './pages/NotFound'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { BaseLayout } from './components/BaseLayout'
import { CountriesPage } from './pages/Countries'
import { DataSeedPage } from './pages/DataSeed'
import { DocumentSectionsPage } from './pages/DocumentsSections'
import { DocumentsPage } from './pages/Documents'
import { UsersPage } from './pages/Users'
import { ProtectedRoute } from './components/ProtectedRoute'
import { CountryRequirements } from './pages/CountryRequirements'
import { CountryRequirementMatrixDetails } from './pages/CountryRequirementMatrixDetails'
import { UserRoles } from './api/users'
import { DocumentsForCountryPage } from './pages/DocumentsForCountry'
import { RfgClassificationTypesPage } from './pages/RfgClassificationTypes'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/countries',
        children: [
          {
            path: '',
            element: (
              <ProtectedRoute>
                <CountriesPage />
              </ProtectedRoute>
            )
          },
          {
            path: ':countryId/requirements',
            element: (
              <ProtectedRoute>
                <CountryRequirements />
              </ProtectedRoute>
            )
          },
          {
            path: ':countryId/rfg-classification-types',
            element: (
              <ProtectedRoute>
                <RfgClassificationTypesPage />
              </ProtectedRoute>
            )
          },
          {
            path: ':countryId/documents',
            element: (
              <ProtectedRoute>
                <DocumentsForCountryPage />
              </ProtectedRoute>
            )
          }
        ]
      },
      {
        path: '/documents',
        children: [
          {
            path: '',
            element: (
              <ProtectedRoute>
                <DocumentsPage />
              </ProtectedRoute>
            )
          },
          {
            path: ':documentId/sections',
            element: (
              <ProtectedRoute>
                <DocumentSectionsPage />
              </ProtectedRoute>
            )
          }
        ]
      },
      {
        path: '/matrix/:id',
        element: (
          <ProtectedRoute>
            <CountryRequirementMatrixDetails />
          </ProtectedRoute>
        )
      },
      {
        path: '/users',
        element: (
          <ProtectedRoute level={UserRoles.Admin}>
            <UsersPage />
          </ProtectedRoute>
        )
      },
      {
        path: '/data-seed',
        element: (
          <ProtectedRoute level={UserRoles.Admin}>
            <DataSeedPage />
          </ProtectedRoute>
        )
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
]

const router = createBrowserRouter(routes)

const AppRouter = () => <RouterProvider router={router} />

export default AppRouter

