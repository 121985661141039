import { Grid, H1, H2, Box, Divider } from '@veracity/vui'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { KeyValueRow } from './KeyValueRow'
import {
  AreDocumentsApplicable,
  CountryRequirementMatrixDetailsResult,
  getCountryRequirementMatrixDetails
} from '../../api/country-requirement-matrixes'
import { KeyCharacteristicInfo } from './KeyCharacteristicsInfo'
import { DocumentsApplicableIcon } from './DocumentsApplicableIcon'
import { Section } from '../../components/styled/Section'
import { CompliancesTable } from './CompliancesTable'
import { DocumentSectionTable } from './DocumentSectionTable'
import { Empty } from '../../components/Empty'
import { MarkdownSummaryBox } from '../../components/MarkdownSummaryBox'
import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { useFetchKeyCharacteristicTypes } from '../../hooks/useFetchKeyCharacteristicTypes'

export const CountryRequirementMatrixDetails = () => {
  const { id } = useParams()
  const [matrixDetails, setMatrixDetails] =
    useState<CountryRequirementMatrixDetailsResult>()

  const { keyCharacteristicTypes } = useFetchKeyCharacteristicTypes()

  useEffect(() => {
    if (id)
      getCountryRequirementMatrixDetails(id).then(result =>
        setMatrixDetails(result)
      )
  }, [id])

  if (!matrixDetails) return null

  return (
    <Box column minW="100%">
      <PageBreadcrumbs
        items={[
          {
            label: matrixDetails.country?.name || '',
            to: `/countries/${matrixDetails.country?.id}/requirements`
          },
          {
            label: 'Matrix Details'
          }
        ]}
      />

      <H1>Matrix Details</H1>
      <Divider borderColor="seaBlue.28" />
      <Section>
        <Grid gap={1} col={1} gridTemplateColumns="1fr 1fr">
          <KeyValueRow title="Country" value={matrixDetails?.country.name} />

          <KeyValueRow
            title="Requirement"
            value={matrixDetails?.requirement.name}
          />

          <KeyValueRow
            title="Technology"
            value={matrixDetails?.technology.name}
          />

          <KeyValueRow
            title="RfG Type"
            value={matrixDetails?.rfgClassificationType.name}
          />
        </Grid>
      </Section>

      <Section>
        <H2>Summary</H2>
        {matrixDetails?.condensedSummaries.map((summary, index) => (
          <MarkdownSummaryBox key={index} markdown={summary} />
        ))}
        {matrixDetails.condensedSummaries.length === 0 && <Empty />}
      </Section>

      <Divider />

      <Section>
        <Box gap={1} alignItems="center">
          <H2>Document Sections</H2>
          <DocumentsApplicableIcon
            areDocumentsApplicable={matrixDetails.areDocumentsApplicable}
          />
        </Box>

        {matrixDetails.areDocumentsApplicable ===
          AreDocumentsApplicable.NotAvailable && (
          <p>{matrixDetails.areDocumentsApplicableComment}</p> // TODO: Add some better view for comment at N/A
        )}

        {matrixDetails.areDocumentsApplicable ===
          AreDocumentsApplicable.Yes && (
          <DocumentSectionTable
            documentSections={matrixDetails.documentSections}
          />
        )}
      </Section>

      <Divider />

      <Section>
        <H2>Compliances</H2>
        <CompliancesTable compliances={matrixDetails.compliances} />
      </Section>

      <Divider />

      <Section>
        <H2>Key Characteristics</H2>
        <Grid rowGap={1}>
          {matrixDetails?.keyCharacteristics.map(keyCharacteristic => (
            <KeyCharacteristicInfo
              key={keyCharacteristic.id}
              keyCharacteristic={keyCharacteristic}
              keyCharacteristicTypes={keyCharacteristicTypes || []}
            />
          ))}
          {!matrixDetails.keyCharacteristics?.length && <Empty />}
        </Grid>
      </Section>
    </Box>
  )
}
