import {
  Box,
  Button,
  Divider,
  H1,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Thead,
  Tr
} from '@veracity/vui'
import { useState } from 'react'

import { Link as RRLink } from 'react-router-dom'
import { DocumentModel, DocumentType } from '../../api/documents'

import { Empty } from '../../components/Empty'
import { OpenDocumentButton } from '../../components/OpenDocumentButton'
import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { useSendUpdateDocument } from '../../hooks/useSendUpdateDocument'

import { useFetchDocuments } from '../../hooks/useFetchDocuments'
import { DocumentEditModal } from './DocumentsEditModal'

export const DocumentsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>()
  const {
    inProgress: fetchInProgress,
    documents,
    fetchDocuments
  } = useFetchDocuments()
  const { inProgress: updateInProgress, sendUpdateRequest } =
    useSendUpdateDocument()

  const getDocumentType = (docType?: DocumentType) => {
    switch (docType) {
      case DocumentType.GridCodeRequirements:
        return 'Grid Code Requirements'
      case DocumentType.GuidelinesAndProcesses:
        return 'Guidelines and Processes'
      case DocumentType.Other:
        return 'Other'
      default:
        return '-'
    }
  }

  const handleAddDocument = () => {
    setIsModalOpen(true)
    setSelectedDocumentId(undefined)
  }

  const handleEditDocument = (documentId?: string) => {
    setIsModalOpen(true)
    setSelectedDocumentId(documentId)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedDocumentId(undefined)
  }

  const handleSaveClicked = (model: DocumentModel, id?: string) => {
    sendUpdateRequest(model, id).then(() => {
      setIsModalOpen(false)
      setSelectedDocumentId(undefined)
      fetchDocuments()
    })
  }

  return (
    <Box column minW="100%" pb="1em">
      <PageBreadcrumbs items={[{ label: 'Documents' }]} />

      <Box p="0 1em" justifyContent="space-between" center>
        <H1>Documents</H1>
        <Button iconLeft="falPlus" text="Add" onClick={handleAddDocument} />
      </Box>

      <DocumentEditModal
        id={selectedDocumentId}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        isSaveInProgress={updateInProgress}
        onSaveClicked={handleSaveClicked}
      />

      <Divider borderColor="seaBlue.28" />

      {fetchInProgress ? (
        <Spinner text="Loading data..." m={10} />
      ) : (
        <Table
          columns={[
            {
              field: 'name',
              title: 'Name'
            },
            {
              field: 'shortName',
              title: 'Short Name'
            },
            {
              field: 'page',
              title: 'Type'
            },
            {
              field: 'actions'
            }
          ]}>
          <Thead />
          <Tbody>
            {documents?.length === 0 ? (
              <Empty />
            ) : (
              documents?.map(document => (
                <Tr key={document.id + '' + document.name}>
                  <Td maxW={250} isTruncated>
                    {document.name}
                  </Td>
                  <Td w={200}>{document.shortName}</Td>
                  <Td w={150}>{getDocumentType(document.documentType)}</Td>
                  <Td w={150}>
                    <Box gap={1}>
                      <OpenDocumentButton documentId={document.id} />
                      <IconButton
                        icon="falArrowRight"
                        size="sm"
                        as={RRLink}
                        to={`/documents/${document.id}/sections`}
                      />

                      <IconButton
                        icon="falPen"
                        size="sm"
                        onClick={() => handleEditDocument(document.id)}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      )}
    </Box>
  )
}
