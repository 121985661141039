import { useEffect, useState } from 'react'
import { CountryDetailsResult, getCountry } from '../api/countries'

export const useFetchCountry = (id?: string) => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<CountryDetailsResult>()
  const [error, setError] = useState<string>()
  useEffect(() => {
    setData(undefined)
    setError(undefined)
    if (id) {
      setInProgress(true)
      getCountry(id)
        .then(res => {
          setInProgress(false)
          setData(res)
        })
        .catch(err => {
          setInProgress(false)
          setError(err.message)
        })
    }
  }, [id])

  return {
    inProgress,
    country: data,
    error
  }
}