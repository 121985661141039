import { useToast } from '@veracity/vui'
import { useState } from 'react'
import { DocumentModel, updateDocument } from '../api/documents'
import { createDocument } from '../api/documents'
import { getErrorMessage } from '../utils/http-utils'

export const useSendUpdateDocument = () => {
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<string>()
  const { showSuccess, showError } = useToast()

  const sendUpdateRequest = (model: DocumentModel, id?: string) => {
    setInProgress(true)
    setError(undefined)

    if (!id)
      return createDocument(model)
        .then(() => {
          setInProgress(false)
          showSuccess('Success. Your changes were saved.')
        })
        .catch(err => {
          const errorMessage = getErrorMessage(err)
          showError(errorMessage)
          setError(errorMessage)
          setInProgress(false)
          throw err
        })

    return updateDocument(id, model)
      .then(() => {
        setInProgress(false)
        showSuccess('Success. Your changes were saved.')
      })
      .catch(err => {
        const errorMessage = getErrorMessage(err)
        showError(errorMessage)
        setError(errorMessage)
        setInProgress(false)
        throw err
      })
  }

  return {
    inProgress,
    error,
    sendUpdateRequest
  }
}
