import { useToast } from '@veracity/vui'
import { useState } from 'react'
import { getErrorMessage } from '../utils/http-utils'
import {
  createDocumentSection,
  DocumentSectionModel,
  updateDocumentSection
} from './../api/document-sections'

export const useSendUpdateDocumentSection = () => {
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<string>()
  const { showSuccess, showError } = useToast()

  const sendUpdateRequest = (model: DocumentSectionModel, id?: string) => {
    setInProgress(true)
    setError(undefined)

    if (!id)
      return createDocumentSection(model)
        .then(() => {
          setInProgress(false)
          showSuccess('Sucess. Your changes were saved.')
        })
        .catch(err => {
          const errorMessage = getErrorMessage(err)
          showError(errorMessage)
          setError(errorMessage)
          setInProgress(false)
        })

    return updateDocumentSection(id, model)
      .then(() => {
        setInProgress(false)
        showSuccess('Sucess. Your changes were saved.')
      })
      .catch(err => {
        const errorMessage = getErrorMessage(err)
        showError(errorMessage)
        setError(errorMessage)
        setInProgress(false)
        throw err
      })
  }

  return {
    inProgress,
    error,
    sendUpdateRequest
  }
}
