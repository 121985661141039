import {
  Box,
  Button,
  Textarea,
  Select,
  Icon,
  ButtonToggleGroup,
  Divider,
  P
} from '@veracity/vui'
import { TreeSelect } from 'antd'

import {
  ComplianceResult,
  ComplianceLevel,
  IsComplianceApplicable
} from '../../../api/country-requirement-matrixes'

import { DocumentsSectionTreeOptions } from './TabsDataUiBinder'

interface CompliancesTabInt {
  compliances: ComplianceResult[]
  setCompliances: (x: ComplianceResult[]) => void
  complianceMethodOptions: { text: string; value: string }[]
  countryDocumentsSectionTree: DocumentsSectionTreeOptions[]
  errorsAreShown: boolean
}

const CompliancesTabUI = ({
  compliances,
  setCompliances,
  complianceMethodOptions,
  countryDocumentsSectionTree,
  errorsAreShown
}: CompliancesTabInt) => {
  const setCompliance = (
    elem: string,
    index: number = -1,
    value: string = ''
  ) => {
    let _compliance: ComplianceResult = {
      id: '',
      isApplicable: IsComplianceApplicable.No,
      level: ComplianceLevel.Unit,
      comment: ''
    }
    let _compliances = compliances

    if (index >= 0) {
      if (compliances[index]) _compliance = compliances[index]
      if (elem === 'methodId')
        _compliance.complianceMethod = {
          id: value,
          name: ' - ',
          countryId: ' - '
        }
      if (elem === 'documentId') {
        _compliance.documentSection = {
          id: value,
          name: ' - ',
          documentShortName: ' - '
        }
      }
      if (elem === 'isApplicable') _compliance.isApplicable = +value
      if (elem === 'complianceLevel') _compliance.level = +value
      if (elem === 'desc') _compliance.comment = value
      //
      _compliances[index] = _compliance
    }
    if (elem === 'new') _compliances = [...compliances, _compliance]

    setCompliances([..._compliances])
  }

  return (
    <Box p={1} maxH={'calc(88vh - 170px)'} column overflow="hidden auto">
      {compliances.map((compliance: ComplianceResult, index: number) => (
        <Box p={0} key={`compliance-${index}`} column position="relative">
          {index > 0 && <Divider mt={4} />}

          <Box
            position="absolute"
            right={5}
            top={16}
            bg="white"
            p={1}
            pb={0}
            cursor="pointer"
            onClick={() => {
              const _copy = [...compliances]
              _copy.splice(index, 1)
              setCompliances([..._copy])
            }}>
            <Icon name="falTrashAlt" size="xs" />
          </Box>

          <P size="sm" mt={1}>
            On level*:{' '}
          </P>
          <ButtonToggleGroup>
            <Button
              isActive={compliance.level === ComplianceLevel.Project}
              onClick={() =>
                setCompliance(
                  'complianceLevel',
                  index,
                  ComplianceLevel.Project + ''
                )
              }>
              Project
            </Button>
            <Button
              isActive={compliance.level === ComplianceLevel.Unit}
              onClick={() =>
                setCompliance(
                  'complianceLevel',
                  index,
                  ComplianceLevel.Unit + ''
                )
              }>
              Unit
            </Button>
          </ButtonToggleGroup>

          <P size="sm" mt={1}>
            Are*:{' '}
          </P>
          <ButtonToggleGroup>
            <Button
              isActive={compliance.isApplicable === IsComplianceApplicable.Yes}
              onClick={() =>
                setCompliance(
                  'isApplicable',
                  index,
                  IsComplianceApplicable.Yes + ''
                )
              }>
              Applicable
            </Button>
            <Button
              isActive={compliance.isApplicable === IsComplianceApplicable.No}
              onClick={() =>
                setCompliance(
                  'isApplicable',
                  index,
                  IsComplianceApplicable.No + ''
                )
              }>
              Not Applicable
            </Button>
            <Button
              isActive={
                compliance.isApplicable === IsComplianceApplicable.NotAvailable
              }
              onClick={() =>
                setCompliance(
                  'isApplicable',
                  index,
                  IsComplianceApplicable.NotAvailable + ''
                )
              }>
              Not Available
            </Button>
          </ButtonToggleGroup>

          {compliance.isApplicable === 0 && (
            <>
              <P size="sm" mt={1}>
                Method selected*:{' '}
              </P>
              <Select
                isInvalid={
                  errorsAreShown && compliance?.complianceMethod?.id === null
                }
                options={complianceMethodOptions}
                onChange={e => setCompliance('methodId', index, e.target.value)}
                value={
                  compliance.complianceMethod
                    ? compliance.complianceMethod.id
                    : ''
                }></Select>

              <P size="sm" mt={1}>
                Document section selected*:{' '}
              </P>

              <TreeSelect
                multiple={false}
                showSearch={false}
                style={{ width: '100%' }}
                status={
                  errorsAreShown && compliance?.documentSection?.id === null
                    ? 'error'
                    : undefined
                }
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: 'auto',
                  zIndex: '2000'
                }}
                placeholder="Please select documents"
                allowClear
                onChange={(newValue: string) =>
                  setCompliance('documentId', index, newValue)
                }
                treeData={countryDocumentsSectionTree}
                value={
                  compliance?.documentSection
                    ? compliance?.documentSection?.id
                    : ''
                }
              />
            </>
          )}

          <P size="sm" mt={1}>
            Description:{' '}
          </P>
          <Textarea
            value={compliance.comment}
            onChange={e => setCompliance('desc', index, e.target.value)}
          />
        </Box>
      ))}
      <Button
        size="sm"
        alignSelf={'end'}
        m={1}
        variant="secondaryDark"
        onClick={() => setCompliance('new')}>
        add compliance
      </Button>
    </Box>
  )
}

export default CompliancesTabUI
