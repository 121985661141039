import { Box } from '@veracity/vui'
import styled from 'styled-components'

const Container = styled(Box)`
  max-width: 1200px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`

export default Container
