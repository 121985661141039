import { DatePicker } from 'antd';
import { Controller } from 'react-hook-form'
import { FormLabel } from './FormLabel'
import { BaseFormItemProps } from './BaseFormItem'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
dayjs.locale('es')

export type DataPickerBaseProps = BaseFormItemProps

export interface DatePickerInputProps extends DataPickerBaseProps { }

export const DatePickerInput = ({
    control,
    formControlName,
    label,
    ...rest
}: DatePickerInputProps) => {

    const dateFormat = 'MM/YYYY';

    return (<Controller
        name={formControlName}
        control={control}
        render={({ field }) => (
            <>
                {!label ? null : <FormLabel label={label} />}
                <DatePicker onChange={field.onChange}
                    defaultValue={dayjs()} format={dateFormat}
                    value={field.value ? dayjs(field.value) : null}
                    picker="month"
                    style={{ width: '100%', borderRadius: 0, border: '1px solid rgb(135, 153, 167)', color: 'rgb(26, 26, 25)' }}
                    {...rest}
                />
            </>
        )} />)
}


