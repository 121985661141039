import styled from 'styled-components'

const TabsOverridesStyles = styled.div`

  width: 100% ;

  .vui-popoverContent {
    max-height: 210px;
  }
  
  .vui-tabsNavBar {
    margin-bottom: 0;
  }
  .vui-listItem.vui-tabsNavBarItem .vui-box {
    flex-direction: row-reverse;
  }

  .vui-listItem.vui-tabsNavBarItem .vui-box svg {
    margin-right: 0;
    margin-left: 8px;
  }
  
  .vui-tabsNavBar .vui-listItem:last-child {
    margin-left: auto;
  }

  .vui-inputHelpText.vui-inputHelpText {
    font-size: 14px;
  }
`

export default TabsOverridesStyles
