import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Divider,
  H2,
  IconButton,
  SelectOptionData,
  Spinner,
  useToast
} from '@veracity/vui'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { EditableColumnsType, BaseTable } from '../../components/Table'
import { useFetchCountry } from '../../hooks/useFetchCountry'
import { useFetchCountryRfg } from '../../hooks/useFetchCountryRfgs'

import * as yup from 'yup'

import {
  RfgClassificationType,
  setCountryRequirements
} from '../../api/rfg-classification-types'

export function RfgClassificationTypesPage() {
  const { countryId } = useParams()

  const { inProgress: fetchInProgressCountry, country } =
    useFetchCountry(countryId)

  const { showError, showSuccess } = useToast()

  const { rfgClassificationTypes: allRfgClassificationTypes } =
    useFetchCountryRfg()

  const [additionalRowVisible, setAdditionalRowVisible] = useState(false)
  const [selectedToEditId, setSelectedToEditId] = useState<string>()

  const rfgClassificationTypeSchema = yup.object({
    name: yup.string().required(),
    description: yup.string().notRequired()
  })

  const { control, handleSubmit, resetField, setValue } =
    useForm<RfgClassificationType>({
      resolver: yupResolver(rfgClassificationTypeSchema),
      defaultValues: {}
    })

  const rfgClassificationTypesList = allRfgClassificationTypes || []

  const {
    inProgress: fetchInProgress,
    rfgClassificationTypes,
    fetchCountryRfg
  } = useFetchCountryRfg(countryId)
  const [_rfgTypes, setRfgTypes] = useState<RfgClassificationType[]>()

  useEffect(() => {
    if (rfgClassificationTypes) setRfgTypes(rfgClassificationTypes)
  }, [rfgClassificationTypes])

  const resetFormValues = () => {
    resetField('name')
    resetField('description')
  }

  const handleCreateRfgType = () => {
    setSelectedToEditId(undefined)
    setAdditionalRowVisible(true)
    resetFormValues()
  }

  const handleEditRowSave = (model: RfgClassificationType, id?: string) => {
    const rfgTypeId = rfgClassificationTypesList.find(
      rfgType => rfgType.name === model.name
    )?.id
    if (rfgTypeId) {
      const tempRfgTypes = _rfgTypes?.filter(
        rfgType => rfgType.id !== (id || '')
      )
      setRfgTypes([{ ...model, id: rfgTypeId }, ...(tempRfgTypes || [])])
    }

    setSelectedToEditId(undefined)
    setAdditionalRowVisible(false)
  }

  const handleSaveRfgType = () => {
    if (countryId && _rfgTypes && additionalRowVisible === false)
      setCountryRequirements(countryId, _rfgTypes)
        .then(() => {
          fetchCountryRfg(countryId)
          showSuccess('Success. Your changes were saved.')
        })
        .catch(err => showError(err))
  }

  const tableData: any[] | undefined = useMemo(() => {
    if (!_rfgTypes) return undefined

    return !additionalRowVisible
      ? [..._rfgTypes]
      : [
          {
            id: '',
            name: '',
            description: ''
          } as RfgClassificationType,
          ..._rfgTypes
        ]
  }, [additionalRowVisible, _rfgTypes])

  const rfgClassificationTypesListFiltered = useMemo(() => {
    const _rfgTypesArr = _rfgTypes?.map(item => item.id) || []
    return rfgClassificationTypesList.filter(
      item => _rfgTypesArr.indexOf(item.id) === -1
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_rfgTypes])

  const handleEditRowClick = (id?: string) => {
    setSelectedToEditId(id)

    const selectedSection = rfgClassificationTypes?.find(x => x.id === id)

    setValue('name', selectedSection?.name || '')
    setValue('description', selectedSection?.description || '')
  }

  const handleRowRemove = (id?: string) => {
    setRfgTypes(_rfgTypes?.filter(x => x.id !== id))
  }

  const isRowInEdit = (rowData: RfgClassificationType) =>
    !!rowData.id ? rowData.id === selectedToEditId : additionalRowVisible

  const columns: EditableColumnsType<any> = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
      formControlName: 'name',
      selectValues: rfgClassificationTypesListFiltered.map(o => {
        return { text: o.name, value: o.name }
      }) as SelectOptionData[]
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      editable: true,
      formControlName: 'description'
    },
    {
      title: '',
      width: '8%',
      dataIndex: 'operation',
      render: (_: any, rfgClassificationType: RfgClassificationType) => {
        const _index =
          _rfgTypes?.findIndex(
            rfgType => rfgType.id === rfgClassificationType.id
          ) || 0
        return (
          <Box gap={1} data-index={_index}>
            {isRowInEdit(rfgClassificationType) ? (
              <>
                <IconButton
                  icon="falSave"
                  size="sm"
                  onClick={handleSubmit(
                    data => handleEditRowSave(data, rfgClassificationType.id),
                    () => {}
                  )}
                />
                <IconButton
                  icon="falTimes"
                  size="sm"
                  onClick={() => {
                    setSelectedToEditId(undefined)
                    setAdditionalRowVisible(false)
                  }}
                />
              </>
            ) : (
              <>
                <IconButton
                  icon="falPen"
                  size="sm"
                  onClick={() => handleEditRowClick(rfgClassificationType.id)}
                />

                <IconButton
                  icon="falTrashAlt"
                  size="sm"
                  onClick={() => handleRowRemove(rfgClassificationType.id)}
                />
              </>
            )}
          </Box>
        )
      }
    }
  ]

  if (fetchInProgress || fetchInProgressCountry)
    return <Spinner text="Loading type's..." m={10} ml={'auto'} mr={'auto'} />

  const countryName = country?.name

  return (
    <Box column minW="100%">
      <PageBreadcrumbs
        items={[
          {
            label: countryName || '',
            to: `/countries`
          },
          { label: 'Rfg Classification Types' }
        ]}
      />

      <Box justifyContent="space-between" center>
        <H2>Rfg Classification Types for {countryName}</H2>
        <Button iconLeft="falPlus" text="Add" onClick={handleCreateRfgType} />
      </Box>

      <Divider borderColor="seaBlue.28" m="1em 0" />

      <BaseTable<RfgClassificationType>
        loading={fetchInProgress}
        dataSource={tableData}
        columns={columns}
        rowKey="id"
        isRowInEdit={isRowInEdit}
        control={control}
        pagination={false}
      />
      <Box justifyContent="space-between" flexDirection={'row-reverse'} m={1}>
        <Button
          iconLeft="falSave"
          variant="primaryDark"
          disabled={additionalRowVisible}
          text="Save"
          size="lg"
          onClick={handleSaveRfgType}
        />
      </Box>
    </Box>
  )
}
