import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getComplianceMethods } from '../api/compliance-methods'

import { getDocumentSections } from '../api/document-sections'
import { getKeyCharacteristicTypes } from '../api/key-characteristics-types'

import { dictionariesActions } from '../store/slices/dictionaries'

const useDictionaries = () => {
  const dispatch = useDispatch()

  const fetchDictionaries = useCallback(async () => {
    const keyCharacteristicTypes = await getKeyCharacteristicTypes()
    dispatch(
      dictionariesActions.setKeyCharacteristicTypes(keyCharacteristicTypes)
    )

    const complianceMethods = await getComplianceMethods()
    dispatch(dictionariesActions.setComplianceMethods(complianceMethods))

    const documentSections = await getDocumentSections()
    dispatch(dictionariesActions.setDocumentSections(documentSections))
  }, [dispatch])

  useEffect(() => {
    fetchDictionaries()
  }, [fetchDictionaries])
}

export default useDictionaries
