import { Icon } from '@veracity/vui'
import { AreDocumentsApplicable } from '../../api/country-requirement-matrixes'

export interface DocumentsApplicableIconProps {
  areDocumentsApplicable?: AreDocumentsApplicable
}

export const DocumentsApplicableIcon = ({
  areDocumentsApplicable
}: DocumentsApplicableIconProps) => {
  let iconName = ''
  switch (areDocumentsApplicable) {
    case AreDocumentsApplicable.Yes:
      iconName = 'falCheckCircle'
      break
    case AreDocumentsApplicable.No:
      iconName = 'falTimesCircle'
      break
    case AreDocumentsApplicable.NotAvailable:
      iconName = 'falQuestionCircle'
      break
    default:
      iconName = 'falTimesCircle'
      break
  }

  return !!iconName ? <Icon size="md" name={iconName} /> : null
}
