import { Button, Popover, PopoverContent, PopoverTrigger } from '@veracity/vui'
import { PropsWithChildren } from 'react'

export const KeyCharacteristicPreview = ({ children }: PropsWithChildren) => {
  return (
    <Popover placement="top-end">
      <PopoverTrigger as={Button} icon="falEye" title="Asset preview">
        Preview
      </PopoverTrigger>
      <PopoverContent p={2} w="40em">
        {children}
      </PopoverContent>
    </Popover>
  )
}
