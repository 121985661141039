import { useToast } from '@veracity/vui'
import { useState } from 'react'
import {
    DraftStatusModel,
    changeCountryDraftStatus,
} from '../api/countries'
import { getErrorMessage } from '../utils/http-utils'

export const useSendReviewCountry = () => {
    const [inProgress, setInProgress] = useState(false)
    const [error, setError] = useState<string>()
    const { showSuccess, showError } = useToast()

    const sendReviewRequest = (status: DraftStatusModel, id: string) => {
        setInProgress(true)
        setError(undefined)

        return changeCountryDraftStatus(id, status)
            .then(() => {
                setInProgress(false)
                showSuccess('Success. Your changes were saved.')
            })
            .catch(err => {
                const errorMessage = getErrorMessage(err)
                showError(errorMessage)
                setError(errorMessage)
                setInProgress(false)
                throw err
            })
    }

    return {
        inProgress,
        error,
        sendReviewRequest
    }
}
