import { Box, Button, Textarea, Icon } from '@veracity/vui'

interface SummariesTabInt {
  summaries: string[]
  setSummaries: (x: string[]) => void
  errorsAreShown?: boolean
}

const SummariesTabUI = ({
  summaries,
  setSummaries,
  errorsAreShown
}: SummariesTabInt) => {
  return (
    <Box p={1} pt={2} maxH={'calc(88vh - 170px)'} column overflow="auto">
      {summaries ? (
        summaries.map((summary: string, index: number) => (
          <Box position="relative" key={`summaries--${index}`}>
            <Textarea
              value={summary}
              mt={1}
              onChange={e => {
                summaries[index] = e.target.value
                setSummaries([...summaries])
              }}
            />
            <Box
              position="absolute"
              right={5}
              top={-8}
              bg="white"
              p={1}
              pb={0}
              cursor="pointer"
              onClick={() => {
                summaries.splice(index, 1)
                setSummaries([...summaries])
              }}>
              <Icon name="falTrashAlt" size="xs" />
            </Box>
          </Box>
        ))
      ) : (
        <Textarea value={''} onChange={e => setSummaries([e.target.value])} />
      )}

      <Button
        size="sm"
        alignSelf={'end'}
        m={1}
        variant="secondaryDark"
        onClick={_e => {
          setSummaries([...summaries, ''])
        }}>
        add additional summary
      </Button>
    </Box>
  )
}

export default SummariesTabUI
