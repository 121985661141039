import { useEffect, useState } from 'react'
import {
  getKeyCharacteristicTypes,
  KeyCharacteristicTypeResult
} from '../api/key-characteristics-types'

export const useFetchKeyCharacteristicTypes = (requirementId?: string) => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<KeyCharacteristicTypeResult[]>()
  const [error, setError] = useState<string>()

  const fetchKeyCharacteristicTypes = () => {
    setInProgress(true)
    setData(undefined)

    return getKeyCharacteristicTypes(requirementId)
      .then(result => {
        setInProgress(false)
        setData(result)
      })
      .catch(err => {
        setInProgress(false)
        setError(err.message)
      })
  }

  useEffect(() => {
    fetchKeyCharacteristicTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirementId])

  return {
    inProgress,
    keyCharacteristicTypes: data,
    error,
    fetchKeyCharacteristicTypes
  }
}
