import { Input, InputProps } from '@veracity/vui'
import { BaseFormItem, BaseFormItemProps } from './BaseFormItem'

export type InputFormControlProps = BaseFormItemProps & InputProps

export const InputFormControl = ({
  label,
  control,
  formControlName,
  ...props
}: InputFormControlProps) => (
  <BaseFormItem
    label={label}
    control={control}
    formControlName={formControlName}>
    <Input {...props} />
  </BaseFormItem>
)
