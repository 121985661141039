import { Box, Button, Icon } from '@veracity/vui'
import { useEffect } from 'react'
import { CountryDetailsResult, CountryModel } from '../../api/countries'
import { ModalDialog } from '../../components/ModalDialog'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  InputFormControl,
  RichTextEditor,
  TextareaFormControl
} from '../../components/Form'
import { convertToHtml, convertToMarkdown } from '../../utils/markdown-utils'

const countrySchema = yup.object({
  name: yup.string().required(),
  isoCode: yup
    .string()
    .matches(/[A-Z]{2}/)
    .required(),
  intro: yup
    .string()
    .required()
    .test(
      'requiredHtml',
      ({ label }) => `${label} is required`,
      value => !!value && !!convertToMarkdown(value)
    ),
  info: yup
    .string()
    .required()
    .test(
      'requiredHtml',
      ({ label }) => `${label} is required`,
      value => !!value && !!convertToMarkdown(value)
    ),
  resultsNote: yup
    .string()
    .required()
    .test(
      'requiredHtml',
      ({ label }) => `${label} is required`,
      value => !!value && !!convertToMarkdown(value)
    )
})

export interface CountryEditModalProps {
  isOpen: boolean
  id?: string
  onClose: () => void
  onSaveClicked: (model: CountryModel, id?: string) => void
  cantEdit: boolean
  countryToEdit?: CountryDetailsResult
}

export const CountryEditModal = ({
  id,
  isOpen,
  countryToEdit,
  onClose,
  onSaveClicked,
  cantEdit
}: CountryEditModalProps) => {
  const { control, handleSubmit, setValue, resetField } = useForm<CountryModel>(
    { resolver: yupResolver(countrySchema, {}) }
  )

  useEffect(() => {
    if (!!countryToEdit) {
      setValue('name', countryToEdit.name)
      setValue('isoCode', countryToEdit.isoCode)
      setValue('intro', countryToEdit.intro)
      setValue('info', convertToHtml(countryToEdit.info))
      setValue('resultsNote', convertToHtml(countryToEdit.resultsNote))
    } else {
      resetField('name')
      resetField('isoCode')
      resetField('intro')
      resetField('info')
      resetField('resultsNote')
    }
  }, [countryToEdit, setValue, resetField])

  const handleSaveClick = (countryToEdit: CountryModel) => {
    console.log(countryToEdit)
    onSaveClicked(countryToEdit, id)
  }

  return (
    <ModalDialog isOpen={isOpen} onClose={onClose} title="Country">
      {cantEdit && (
        <Box display="flex">
          <Icon size="sm" name={'falInfoCircle'}></Icon>
          <p>To enable edit, previous changes needs to be review</p>
        </Box>
      )}
      <InputFormControl
        control={control}
        label="Name*"
        formControlName="name"
      />
      <InputFormControl
        control={control}
        label="ISO Code*"
        formControlName="isoCode"
        maxLength={2}
      />

      <TextareaFormControl
        control={control}
        label="Intro"
        formControlName="intro"
        rows={50}
        h={600}
        minH={600}
      />

      <RichTextEditor
        label="Country info"
        control={control}
        formControlName="info"
      />

      <RichTextEditor
        label="Results Note"
        control={control}
        formControlName="resultsNote"
      />

      <Box p="1em 1em 0" w="100%" justifyContent="flex-end">
        <Button onClick={handleSubmit(handleSaveClick)} disabled={cantEdit}>
          Save
        </Button>
      </Box>
    </ModalDialog>
  )
}
