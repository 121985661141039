import React from 'react'
import { InputFormControl, SelectFormControl } from '../Form'
import { Control } from 'react-hook-form'
import { InputType, SelectOptionData } from '@veracity/vui'

export interface EditableCellProps {
  editing: boolean
  dataIndex: string
  inputType?: InputType
  index: number

  formControlName?: string
  control?: Control
  selectValues?: SelectOptionData[]
  children: React.ReactNode
}

export const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  index,

  control,
  formControlName,
  selectValues,
  children,

  ...restProps
}: EditableCellProps) => (
  <td key={index} {...restProps}>
    {editing && control && formControlName ? (
      <>
        {!selectValues && (
          <InputFormControl
            control={control}
            formControlName={formControlName}
            type={inputType}
          />
        )}
        {selectValues && (
          <SelectFormControl
            formControlName={formControlName}
            control={control}
            options={selectValues}
          />
        )}
      </>
    ) : (
      children
    )}
  </td>
)
