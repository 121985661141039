import { Controller } from 'react-hook-form'
import {
  Box,
  DragAndDrop,
  DragAndDropProps,
  IconButton,
  T
} from '@veracity/vui'
import { BaseFormItemProps } from './BaseFormItem'

export type FileUploadFormControlProps = BaseFormItemProps & DragAndDropProps

export const FileUploadFormControl = ({
  formControlName,
  control,
  ...rest
}: FileUploadFormControlProps) => {
  const getEventObject = ([file]: File[]) => ({ target: { value: file } })

  return (
    <Controller
      name={formControlName}
      control={control}
      render={({ field, fieldState }) => (
        <Box py={1} w="100%" column>
          <DragAndDrop
            onFilesAdded={files => field.onChange(getEventObject(files))}
            text="Click of drag file to this area"
            variant={fieldState.error ? 'outlined' : 'default'}
            w="100%"
            {...rest}
          />
          {!!field.value && (
            <Box p={1} justifyContent="space-between">
              <T>{field.value?.name}</T>

              <IconButton
                icon="falTrashAlt"
                onClick={() => field.onChange(getEventObject([]))}
              />
            </Box>
          )}
        </Box>
      )}
    />
  )
}
