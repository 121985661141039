export const KeyValueRow = ({
  title,
  value
}: {
  title: string
  value?: string
}) => (
  <>
    <span>{title}:</span>
    <strong>{value}</strong>
  </>
)
