import { Box, Button, Divider, H1, IconButton, useToast } from '@veracity/vui'
import { useMemo, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'

import { BaseTable, EditableColumnsType } from '../../components/Table'
import {
  postInvite,
  removeUser,
  setRole,
  UserRoles,
  UsersListResult
} from '../../api/users'
import { useFetchUsersList } from '../../hooks/useFetchUsers'
import { getErrorMessage } from '../../utils/http-utils'

export interface UserModel {
  email: string
  role?: UserRoles
}

const userSchema = yup.object({
  email: yup.string().email().required(),
  role: yup.number().notRequired()
})

export const UsersPage = () => {
  const [selectedToEditId, setSelectedToEditId] = useState<string>()
  const [additionalRowVisible, setAdditionalRowVisible] = useState(false)
  const {
    inProgress: fetchInProgress,
    usersList,
    fetchUsersList
  } = useFetchUsersList()

  const { showError, showSuccess } = useToast()

  const { control, handleSubmit, setValue, resetField } = useForm<UserModel>({
    resolver: yupResolver(userSchema),
    defaultValues: {}
  })

  const handleEditRowClick = (id?: string) => {
    setAdditionalRowVisible(false)
    setSelectedToEditId(id)

    const _user = usersList?.find(elem => elem.id === id)
    if (_user) {
      setValue('email', _user.email)
      setValue('role', _user.role)
    }
  }

  const handleAddRowClick = () => {
    setValue('role', UserRoles.Viewer)
    setAdditionalRowVisible(true)
    resetFormValues()
  }

  const tableData: UsersListResult[] | undefined = useMemo(() => {
    if (!usersList) return undefined
    return !additionalRowVisible
      ? [...usersList]
      : [{ id: '' } as UsersListResult, ...usersList]
  }, [additionalRowVisible, usersList])

  const handleEditRowEditCancel = () => {
    setSelectedToEditId(undefined)
    setAdditionalRowVisible(false)
    resetFormValues()
  }

  const resetFormValues = () => {
    resetField('email')
    resetField('role', { defaultValue: UserRoles.Viewer })
  }

  const handleEditRowSave = (model: UserModel, userId?: string) => {
    const _model = model as UsersListResult
    if (!userId)
      postInvite(_model.email)
        .then(() => {
          showSuccess('Success. Your invitation wos send.')
          fetchUsersList()
          setAdditionalRowVisible(false)
        })
        .catch(err => {
          const errorMessage = getErrorMessage(err)
          showError(errorMessage)
        })
    else if (_model.role !== undefined || _model.role !== null) {
      setRole(userId, _model.role || 0)
        .then(() => {
          showSuccess('Success. Your changes were saved.')
          setSelectedToEditId(undefined)
          fetchUsersList()
        })
        .catch(err => {
          const errorMessage = getErrorMessage(err)
          showError(errorMessage)
        })
    }
  }

  const handleRemoveRow = (userId: string) => {
    removeUser(userId)
      .then(() => {
        showSuccess('Success. Your changes were saved.')
        setSelectedToEditId(undefined)
        fetchUsersList()
      })
      .catch(err => {
        const errorMessage = getErrorMessage(err)
        showError(errorMessage)
      })
  }

  const isRowInEdit = (rowData: UsersListResult) =>
    !!rowData.id ? rowData.id === selectedToEditId : additionalRowVisible

  const columns: EditableColumnsType<any> = [
    {
      key: 'fullName',
      title: 'Full name',
      dataIndex: 'fullName',
      width: '25%',
      editable: false,
      formControlName: 'fullName'
    },
    {
      key: 'email',
      title: 'E-mail',
      dataIndex: 'email',
      width: '30%',
      editable: true,
      formControlName: 'email',
      defaultSortOrder: 'descend'
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      width: '10%',
      editable: selectedToEditId !== undefined,
      formControlName: 'role',
      inputType: 'select',
      selectValues: [
        { text: 'Admin', value: UserRoles.Admin },
        { text: 'Moderator', value: UserRoles.Moderator },
        { text: 'Viewer', value: UserRoles.Viewer }
      ]
    },
    {
      title: 'Actions',
      width: '10%',
      dataIndex: 'operation',
      render: (_: any, user: UsersListResult) => {
        return (
          <Box gap={1}>
            {isRowInEdit(user) ? (
              <>
                <IconButton
                  icon="falSave"
                  size="sm"
                  onClick={handleSubmit(
                    data => handleEditRowSave(data, user.id),
                    () => {}
                  )}
                />
                <IconButton
                  icon="falTimes"
                  size="sm"
                  onClick={() => handleEditRowEditCancel()}
                />
              </>
            ) : (
              <>
                <IconButton
                  icon="falPen"
                  size="sm"
                  onClick={() => handleEditRowClick(user.id)}
                />
                <IconButton
                  icon="falTrashAlt"
                  size="sm"
                  onClick={() => handleRemoveRow(user.id)}
                />
              </>
            )}
          </Box>
        )
      }
    }
  ]

  return (
    <Box column minW="100%" pb="1em">
      <PageBreadcrumbs items={[{ label: 'Users', to: '/users' }]} />

      <Box p="0 1em" justifyContent="space-between" center>
        <H1>Users</H1>
        <Button
          iconLeft="falPlus"
          text="Add"
          disabled={additionalRowVisible || !!selectedToEditId}
          onClick={handleAddRowClick}
        />
      </Box>

      <Divider borderColor="seaBlue.28" />

      <BaseTable<UsersListResult>
        loading={fetchInProgress || usersList === undefined}
        dataSource={tableData}
        columns={columns}
        rowKey="id"
        isRowInEdit={isRowInEdit}
        control={control}
        pagination={false}
      />
    </Box>
  )
}
