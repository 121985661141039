import { Heading, Button, Select, ButtonToggleGroup, P } from '@veracity/vui'
import SelectWrapper from '../../components/styled/CountryRequirements/SelectWrapper'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { DictionaryEntry } from '../../store/slices/dictionaries'
import {
  CountryRequirementMatrixDetailsResult,
  getCountryRequirementMatrixDetails,
  MatrixDetailsMain,
  ReadyFlags,
  setCountryRequirementMatrixDetails,
  technologyListInt
} from '../../api/country-requirement-matrixes'
import {
  mapMatrixDetailsCompliancesToMatrixCompliances,
  mapKeyCharacteristicsIdsToZeros
} from '../../utils/country-requirement-utils'
import { getErrorMessage } from '../../utils/http-utils'

interface CloneControlsUiProps {
  technology: string
  technologyId: string
  rfgTypeName: string
  rfgClassificationTypeId: string
  matricesDataSetter: (x: CountryRequirementMatrixDetailsResult) => void
  technologyList: technologyListInt[]
  mainIds: MatrixDetailsMain
  currentId: string
  showSuccess: (x: string) => void
  showError: (x: string) => void
}
enum matrixPartsEnum {
  'doc' = 1,
  'sum',
  'key',
  'com'
}
export const CloneControlsUi = ({
  technology,
  technologyId,
  rfgTypeName,
  rfgClassificationTypeId,

  matricesDataSetter,
  technologyList,

  mainIds,
  currentId,
  showSuccess,
  showError
}: CloneControlsUiProps) => {
  const rfgClassificationTypes = useSelector<RootState, DictionaryEntry[]>(
    state => state.dictionaries.rfgClassificationTypes
  )
  const [cloneActive, setCloneActive] = useState(false)
  const [cloneTechnologyId, setCloneTechnologyId] = useState(technologyId)
  const [cloneRfgClassificationTypeId, setCloneRfgTypeId] = useState(
    rfgClassificationTypeId
  )
  const [cloneMatrixPart, setCloneMatrixPart] = useState<matrixPartsEnum[]>([])

  const technologyOptions = technologyList.map(
    ({ technologyId, technologyName }) => {
      return { text: technologyName, value: technologyId }
    }
  )
  const rfgTypesOptions = rfgClassificationTypes.map(({ name, id }) => {
    return { text: name, value: id }
  })
  const matrixPartsOptions = [
    { text: 'document', value: matrixPartsEnum.doc },
    { text: 'summaries', value: matrixPartsEnum.sum },
    { text: 'key characteristics', value: matrixPartsEnum.key },
    { text: 'compliances', value: matrixPartsEnum.com }
  ]

  const isSelectedForCloning = (part: matrixPartsEnum) => {
    if (cloneMatrixPart?.length === 0) return true
    return cloneMatrixPart.includes(part)
  }

  const cloneMethod = () => {
    //
    // technologyList, cloneTechnologyId, cloneRfgClassificationTypeId
    //
    const _technologyForCloning = technologyList.find(
      (item: { technologyId: string }) => item.technologyId === technologyId
    )
    if (!_technologyForCloning || !currentId) return false

    const matrixForCloning = Object.values(_technologyForCloning.matrices).find(
      item => item.id === currentId
    )

    if (matrixForCloning) {
      const ReadyFlags: ReadyFlags = {
        areDocumentsReadyFlag: false,
        areCondensedSummariesReadyFlag: false,
        areKeyCharacteristicsReadyFlag: false,
        areCompliancesReadyFlag: false
      }

      const _technology = technologyList.find(
        (item: { technologyId: string }) =>
          item.technologyId === cloneTechnologyId
      )
      if (!_technology) return false
      let _matrix = _technology.matrices[cloneRfgClassificationTypeId]

      setCountryRequirementMatrixDetails(
        {
          ...mainIds,
          technologyId: cloneTechnologyId,
          rfgClassificationTypeId: cloneRfgClassificationTypeId
        },
        ReadyFlags,
        {
          areDocumentsApplicable: isSelectedForCloning(matrixPartsEnum.doc)
            ? matrixForCloning?.areDocumentsApplicable
            : _matrix?.areDocumentsApplicable,
          areDocumentsApplicableComment:
            (isSelectedForCloning(matrixPartsEnum.doc)
              ? matrixForCloning?.areDocumentsApplicableComment
              : _matrix?.areDocumentsApplicableComment) || '',
          documentSections:
            (isSelectedForCloning(matrixPartsEnum.doc)
              ? matrixForCloning?.documentSections.map(
                  docSection => docSection.id
                )
              : _matrix?.documentSections.map(docSection => docSection.id)) ||
            []
        },
        isSelectedForCloning(matrixPartsEnum.sum)
          ? matrixForCloning?.condensedSummaries || []
          : _matrix?.condensedSummaries || [],
        mapKeyCharacteristicsIdsToZeros(
          isSelectedForCloning(matrixPartsEnum.key)
            ? matrixForCloning?.keyCharacteristics || []
            : _matrix?.keyCharacteristics || []
        ),
        mapMatrixDetailsCompliancesToMatrixCompliances(
          isSelectedForCloning(matrixPartsEnum.com)
            ? matrixForCloning?.compliances || []
            : _matrix?.compliances || []
        ),
        _matrix.id
      )
        .then(({ data }) => {
          if (_matrix.id || data?.id)
            getCountryRequirementMatrixDetails(_matrix.id || data?.id).then(
              matrixDetails => {
                matricesDataSetter(matrixDetails)
                showSuccess('Success Your changes were saved.')
              }
            )
        })
        .catch(error => showError(getErrorMessage(error)))
    }

    setCloneActive(false)
  }

  if (cloneActive)
    return (
      <>
        <Heading size="h3">{technology}</Heading>
        <Heading size="h6" mt={1}>
          RfG Classification Type: {rfgTypeName}
        </Heading>
        <P>Will by clone to:</P>
        <SelectWrapper>
          <Select
            size="sm"
            value={cloneTechnologyId}
            options={technologyOptions}
            onChange={e => {
              const { value } = e.target

              setCloneTechnologyId(value)
            }}></Select>
        </SelectWrapper>
        <SelectWrapper>
          <Select
            size="sm"
            matchWidth={true}
            value={cloneRfgClassificationTypeId}
            options={rfgTypesOptions}
            onChange={e => {
              const { value } = e.target

              setCloneRfgTypeId(value)
            }}></Select>
        </SelectWrapper>
        <SelectWrapper>
          <Select
            size="sm"
            matchWidth={true}
            value={cloneMatrixPart}
            isMultiple={true}
            options={matrixPartsOptions}
            placeholder={'Please select part for cloning'}
            onChange={e => {
              const { value } = e.target
              const _value = parseInt(value)
              if (cloneMatrixPart.includes(_value))
                cloneMatrixPart.splice(cloneMatrixPart.indexOf(_value), 1)
              else cloneMatrixPart.push(_value)
              setCloneMatrixPart([...cloneMatrixPart])
            }}></Select>
        </SelectWrapper>

        <ButtonToggleGroup ml={16}>
          <Button
            isActive={true}
            onClick={() => {
              cloneMethod()
            }}
            size="sm"
            text="Save Clone To"></Button>
          <Button
            onClick={() => {
              setCloneActive(false)
            }}
            size="sm"
            text="Return"></Button>
        </ButtonToggleGroup>
      </>
    )
  return (
    <>
      <Heading size="h3">{technology}</Heading>
      <Heading size="h6" mt={1}>
        RfG Classification Type: {rfgTypeName}
      </Heading>
      <Button
        onClick={() => setCloneActive(true)}
        size="md"
        alignSelf="end"
        position="absolute"
        top={60}
        text="Clone To"
        variant="primaryLight"
      />
    </>
  )
}
