import { useEffect, useState } from 'react'
import { DocumentResult } from '../api/documents'
import { getDocuments } from './../api/documents'

export const useFetchDocuments = () => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<DocumentResult[]>()
  const [error, setError] = useState<string>()

  const fetchDocuments = () => {
    setInProgress(true)
    setData(undefined)
    setError(undefined)
    getDocuments()
      .then(res => setData(res))
      .catch(err => setError(err.message))
      .finally(() => setInProgress(false))
  }

  useEffect(() => {
    fetchDocuments()
  }, [])

  return {
    inProgress,
    documents: data,
    error,
    fetchDocuments
  }
}
