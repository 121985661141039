import { useEffect, useState } from 'react'

import {
  getCountryRequirements,
  getRfgClassificationTypes,
  RfgClassificationType
} from '../api/rfg-classification-types'

export const useFetchCountryRfg = (id?: string) => {
  const [inProgress, setInProgress] = useState(false)
  const [data, setData] = useState<RfgClassificationType[]>()
  const [error, setError] = useState<string>()

  const fetchCountryRfg = (id: string) => {
    setData(undefined)
    setError(undefined)
    setInProgress(true)
    getCountryRequirements(id)
      .then((data: RfgClassificationType[] | undefined) => {
        setInProgress(false)
        setData(data)
      })
      .catch((err: { message: string | undefined }) => {
        setInProgress(false)
        setError(err.message)
      })
  }

  const fetchAllRfg = () => {
    setData(undefined)
    setError(undefined)
    setInProgress(true)
    getRfgClassificationTypes()
      .then((data: RfgClassificationType[] | undefined) => {
        setInProgress(false)
        setData(data)
      })
      .catch((err: { message: string | undefined }) => {
        setInProgress(false)
        setError(err.message)
      })
  }

  useEffect(() => {
    if (id) fetchCountryRfg(id)
    else fetchAllRfg()
  }, [id])

  return {
    inProgress,
    rfgClassificationTypes: data,
    error,
    fetchCountryRfg
  }
}
