export const tryParseJson = (str: string) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return undefined
  }
}

export const ifIsValidJsonStr = (str: string, def: string = "[]") => {
  if (!tryParseJson(str)) return def;
  
  return str
}
