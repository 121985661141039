import {
  Spinner,
  Table,
  Tbody,
  Tr,
  Td,
  Box,
  IconButton,
  H1,
  Divider,
  Button,
  Switch,
  Grid
} from '@veracity/vui'
import { Link as RRLink, useNavigate } from 'react-router-dom'
import { useFetchCountries } from '../../hooks/useFetchCountries'
import { Empty } from '../../components/Empty'
import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { CountryEditModal } from './CountryEditModal'
import { useState } from 'react'
import { useSendUpdateCountry } from '../../hooks/useSendUpdateCountry'
import {
  CountryDetailsResult,
  CountryModel,
  DraftStatus,
  getCountryDraft,
  DraftStatusModel,
  CountryStatusModel,
  getCountry
} from '../../api/countries'
import { CountryPreviewModal } from './CountryPreviewModal'
import { useSendReviewCountry } from '../../hooks/useSendReviewCountry'
import { useSendUpdateCountryStatus } from '../../hooks/useSendUpdateCountryStatus'
import { convertToMarkdown } from '../../utils/markdown-utils'

export const CountriesPage = () => {
  const {
    inProgress: fetchInProgress,
    countries,
    fetchCountries
  } = useFetchCountries()
  const navigate = useNavigate()

  const { inProgress: updateInProgress, sendRequest } = useSendUpdateCountry()
  const { inProgress: updateInProgressReview, sendReviewRequest } =
    useSendReviewCountry()
  const { inProgress: updateCountryStatus, sendUpdateCountryStatusRequest } =
    useSendUpdateCountryStatus()
  const [selectedToEditId, setSelectedToEditId] = useState<string>()
  const [selectedCountryToEdit, setSelectedCountryToEdit] =
    useState<CountryDetailsResult>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const [selectedReviewCountry, setSelectedReviewCountry] =
    useState<CountryDetailsResult>()
  const [isEditable, setIsEditable] = useState<boolean>(false)

  const handleEditCountry = (countryId: string, canEdit: boolean) => {
    setSelectedToEditId(countryId)
    getCountry(countryId).then(country => {
      setIsEditable(canEdit)
      setSelectedCountryToEdit(country)
      setIsModalOpen(true)
    })
  }

  const closeEditModal = () => {
    setSelectedToEditId(undefined)
    setIsModalOpen(false)
  }

  const handleCreateCountry = () => {
    setSelectedToEditId(undefined)
    setSelectedCountryToEdit(undefined)
    setIsEditable(false)
    setIsModalOpen(true)
  }

  const handleEditCountrySave = (model: CountryModel, id?: string) => {
    sendRequest(
      {
        ...model,
        resultsNote: convertToMarkdown(model.resultsNote),
        info: convertToMarkdown(model.info)
      } as CountryModel,
      id
    )?.then(() => {
      setIsModalOpen(false)
      setSelectedToEditId(undefined)
      return fetchCountries()
    })
  }

  const handleOpenPreviewModal = (id: string) => {
    getCountryDraft(id)
      .then(result => {
        setSelectedReviewCountry(result)
      })
      .finally(() => setIsPreviewModalOpen(true))
  }

  const onReviewChanges = (id: string, status: DraftStatus) => {
    const statusType: DraftStatusModel = { status }
    sendReviewRequest(statusType, id).then(() => {
      setIsPreviewModalOpen(false)
      return fetchCountries()
    })
  }

  const handleSwitchChange = (id: string, value: boolean) => {
    value = !value
    const valueType: CountryStatusModel = { isActive: value }
    sendUpdateCountryStatusRequest(valueType, id).then(() => {
      return fetchCountries()
    })
  }

  return (
    <Box column minW="100%">
      <PageBreadcrumbs items={[{ label: 'Countries' }]} />

      <Box p="0 1em" justifyContent="space-between" center>
        <H1>Countries</H1>

        <Button iconLeft="falPlus" text="Add" onClick={handleCreateCountry} />
      </Box>

      <Divider borderColor="seaBlue.28" />

      <CountryEditModal
        id={selectedToEditId}
        isOpen={isModalOpen}
        countryToEdit={selectedCountryToEdit}
        onClose={closeEditModal}
        onSaveClicked={handleEditCountrySave}
        cantEdit={isEditable}
      />
      <CountryPreviewModal
        isOpen={isPreviewModalOpen}
        country={selectedReviewCountry}
        onClose={() => setIsPreviewModalOpen(false)}
        onReviewDraft={onReviewChanges}
      />

      {fetchInProgress ||
      updateInProgress ||
      updateInProgressReview ||
      updateCountryStatus ? (
        <Spinner text="Loading countries..." m={10} />
      ) : (
        <Table>
          <Tbody>
            {countries?.length === 0 ? (
              <Empty />
            ) : (
              countries?.map(country => (
                <Tr key={country.id}>
                  <Td
                    title="Country"
                    minW={200}
                    cursor="pointer"
                    onClick={() =>
                      navigate(`/countries/${country.id}/requirements`)
                    }>
                    {country.name}
                  </Td>
                  <Td w="15em">
                    <Grid gridTemplateColumns="5em repeat(5, 2em)">
                      <Switch
                        mr={2}
                        size="sm"
                        checked={country.isPublished}
                        innerLabelOff="Editable"
                        innerLabelOn="Locked"
                        onClick={() =>
                          handleSwitchChange(country.id, country.isPublished)
                        }
                      />

                      <Box>
                        {country.hasChanges && (
                          <IconButton
                            icon="falFileCheck"
                            size="sm"
                            onClick={() => handleOpenPreviewModal(country.id)}
                          />
                        )}
                      </Box>

                      <IconButton
                        icon="falArrowRight"
                        size="sm"
                        as={RRLink}
                        to={`/countries/${country.id}/requirements`}
                      />

                      <IconButton
                        icon="falPen"
                        size="sm"
                        onClick={() =>
                          handleEditCountry(country.id, country.hasChanges)
                        }
                      />

                      <IconButton
                        icon="falThList"
                        title="RfG classification types"
                        size="sm"
                        as={RRLink}
                        to={`/countries/${country.id}/rfg-classification-types`}
                      />

                      <IconButton
                        icon="falFileAlt"
                        title="documents"
                        size="sm"
                        as={RRLink}
                        to={`/countries/${country.id}/documents`}
                      />
                    </Grid>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      )}
    </Box>
  )
}
