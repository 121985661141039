import { Box } from '@veracity/vui'
import ReactQuill from 'react-quill'
import { Controller } from 'react-hook-form'

import { FormLabel } from './FormLabel'
import { BaseFormItemProps } from './BaseFormItem'

export type RichTextEditorProps = BaseFormItemProps

export interface ComponentProps extends RichTextEditorProps {}

export const RichTextEditor = ({
  label,
  control,
  formControlName
}: ComponentProps) => {
  return (
    <Box minW="100%" maxW="100%" display="grid" mt={4} minH={600} mb={5}>
      <Controller
        name={formControlName}
        control={control}
        render={({ field, fieldState }) => (
          <Box display="block">
            {!label ? null : <FormLabel label={label} />}
            <Box
              column
              borderStyle="solid"
              borderWidth={fieldState.error ? 1 : 0}
              borderColor="energyRed.main"
              h="400px"
              w="100%">
              <ReactQuill
                theme="snow"
                style={{
                  height: '356px',
                  maxHeight: '100%',
                  overflowY: 'visible'
                }}
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ size: [14, 16] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                      { list: 'ordered' },
                      { list: 'bullet' },
                      { indent: '-1' },
                      { indent: '+1' }
                    ],
                    ['link', 'image'],
                    ['clean']
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false
                  }
                }}
                formats={[
                  'header',
                  'font',
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                  'image'
                ]}
                value={field.value}
                onChange={field.onChange}
              />
            </Box>
          </Box>
        )}
      />
    </Box>
  )
}
