import { DocumentSectionResult } from '../../api/document-sections'
import { Table, Tbody, Td, Th, Thead, Tr } from '@veracity/vui'
import { Empty } from '../../components/Empty'
import { OpenDocumentButton } from '../../components/OpenDocumentButton'

export interface DocumentSectionTableProps {
  documentSections: DocumentSectionResult[]
}

export const DocumentSectionTable = ({
  documentSections
}: DocumentSectionTableProps) => {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th title="Document" />
            <Th title="Name" />
            <Th title="Page" />
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {documentSections.map(section => (
            <Tr key={section.id}>
              <Td>{section.documentShortName}</Td>
              <Td>{section.name}</Td>
              <Td>{section.page}</Td>
              <Td>
                <OpenDocumentButton
                  documentId={section.documentId}
                  page={section.page}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {documentSections.length === 0 && <Empty />}
    </>
  )
}
