import {
  ComplianceResult,
  IsComplianceApplicable,
  AreDocumentsApplicable,
  MatrixDetailsCompliances,
  KeyCharacteristicsModel
} from '../api/country-requirement-matrixes'
import { tryParseJson } from './json-utils'

export const validateDocumentsPart = (
  areDocumentsApplicable: AreDocumentsApplicable,
  areDocumentsApplicableComment: string,
  documentSectionsSelected: string[]
) => {
  let currentValidation = true
  if (
    currentValidation &&
    areDocumentsApplicable === AreDocumentsApplicable.NotAvailable &&
    (areDocumentsApplicableComment + '').trim().length <= 3
  )
    currentValidation = false

  if (
    currentValidation &&
    areDocumentsApplicable === AreDocumentsApplicable.Yes &&
    documentSectionsSelected.length === 0
  )
    currentValidation = false

  return currentValidation
}

export const validateSummariesPart = (reducedSummaries: string[]) => {
  let currentValidation = 1
  if (reducedSummaries) return currentValidation
  return false
}

export const validateKeyCharacteristicsPart = (
  keyCharacteristics: KeyCharacteristicsModel[]
) => {
  if (!keyCharacteristics || keyCharacteristics.length === 0) return false

  const keyCharacteristicsError: KeyCharacteristicsModel | undefined =
    keyCharacteristics.find(currentCharacteristic => {
      const { file, plotData, keyCharacteristicTypeId } = currentCharacteristic
      if (!keyCharacteristicTypeId) return currentCharacteristic
      else if (!file && !tryParseJson(plotData || ''))
        return currentCharacteristic // TODO: Check this - because sometimes it can be ok to not upload files
      return null
    })

  return keyCharacteristicsError === undefined
}

const findComplianceWithError = (currentCompliances: ComplianceResult) => {
  const { level, isApplicable, documentSection, complianceMethod, comment } =
    currentCompliances

  if (level && (level < 0 || level > 1)) return currentCompliances

  if (isApplicable === null) return currentCompliances
  else {
    if (isApplicable === IsComplianceApplicable.No) {
      // ok no other conditions
    } else if (isApplicable === IsComplianceApplicable.NotAvailable) {
      // needs comment
      if (!comment || (comment && comment.trim().length < 2)) {
        return currentCompliances
      }
    } else if (isApplicable === IsComplianceApplicable.Yes) {
      if (
        !documentSection ||
        (documentSection && documentSection.id.length < 2)
      ) {
        return currentCompliances
      }

      if (
        !complianceMethod ||
        (complianceMethod && complianceMethod.id.length < 2)
      ) {
        return currentCompliances
      }
    }
  }
}

export const validateCompliancesPart = (compliances: ComplianceResult[]) => {
  return !compliances.some(findComplianceWithError)
}

export const getStepWithError = (
  areDocumentsApplicable: AreDocumentsApplicable,
  areDocumentsApplicableComment: string,
  documentSectionsSelected: string[],
  keyCharacteristics: KeyCharacteristicsModel[],
  compliances: ComplianceResult[]
) => {
  const isDocumentParkValidated = validateDocumentsPart(
    areDocumentsApplicable,
    areDocumentsApplicableComment,
    documentSectionsSelected
  )
  if (!isDocumentParkValidated) return 1

  const areKeyCharacteristicsValidated =
    validateKeyCharacteristicsPart(keyCharacteristics)
  if (!areKeyCharacteristicsValidated) return 3

  const areCompliancesValidated = validateCompliancesPart(compliances)
  if (!areCompliancesValidated) return 4

  return 0
}

export const mapMatrixDetailsCompliancesToMatrixCompliances = (
  matrixCompliances: ComplianceResult[]
): MatrixDetailsCompliances[] => {
  return matrixCompliances.map(compliance => {
    let _compliance: MatrixDetailsCompliances = {
      complianceLevel: compliance.level,
      ...compliance
    }
    if (!!compliance.documentSection)
      _compliance.documentSectionId = compliance.documentSection.id
    if (!!compliance.complianceMethod)
      _compliance.complianceMethodId = compliance.complianceMethod.id
    return _compliance
  })
}

export const mapKeyCharacteristicsIdsToZeros = (
  matrixKeyCharacteristics: KeyCharacteristicsModel[]
): KeyCharacteristicsModel[] => {
  return matrixKeyCharacteristics.map(keyCharacteristic => {
    keyCharacteristic.id = '0'
    return keyCharacteristic
  })
}
