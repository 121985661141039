import { Tabs, Tab, Spinner } from '@veracity/vui'
import { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  CountryRequirementMatrixDetailsResult,
  MatrixDetailsMain,
  AreDocumentsApplicable,
  KeyCharacteristicsResult,
  ComplianceResult,
  setCountryRequirementMatrixDetails,
  MatrixDetailsDocuments,
  getCountryRequirementMatrixDetails,
  IsComplianceApplicable,
  ComplianceLevel,
  ReadyFlags
} from '../../../api/country-requirement-matrixes'
import { useFetchDocumentsSectionsGrouped } from '../../../hooks/useFetchDocumentsSectionsGrouped'
import { useFetchKeyCharacteristicTypes } from '../../../hooks/useFetchKeyCharacteristicTypes'
import { useMarkingsOnTabs } from '../../../hooks/useMarkingsOnTabs'

import { RootState } from '../../../store'
import { DictionaryEntry } from '../../../store/slices/dictionaries'
import { getStepWithError } from '../../../utils/country-requirement-utils'
import CompliancesTabUI from './CompliancesTabUI'
import DocumentsTabUI from './DocumentsTabUI'
import KeyCharacteristicsTabUI from './KeyCharacteristicsTabUI'
import SummariesTabUI from './SummariesTabUI'

import TabsOverridesStyles from './TabsOverridesStyles'

interface TabsDataUiBinderProps {
  data: CountryRequirementMatrixDetailsResult
  mainIds: MatrixDetailsMain
  matrixId: string
  updateData: (x: CountryRequirementMatrixDetailsResult) => void
  showSuccess: (x: string) => void
  showError: (x: string) => void
  activeTab: number
  setActiveTab: (x: number) => void
}
export interface DocumentsSectionTreeOptions {
  value: string
  title: string
  selectable: boolean
  children: { value: string; title: string }[]
}
export const TabsDataUiBinder = ({
  data,
  mainIds,
  matrixId,
  updateData,
  showSuccess,
  showError,
  activeTab = 0,
  setActiveTab
}: TabsDataUiBinderProps) => {
  const { documents } = useFetchDocumentsSectionsGrouped(mainIds.countryId)

  const countryDocumentsSectionTree: DocumentsSectionTreeOptions[] =
    useMemo(() => {
      if (!documents) return []

      return documents?.map(item => {
        return {
          value: item.id,
          title: item.name,
          selectable: false,
          children: item.documentSections.map(
            (subItem: { id: string; description: string; name: string }) => {
              return {
                value: subItem.id,
                title: subItem.description
                  ? subItem.name + ' - ' + subItem.description
                  : subItem.name
              }
            }
          )
        }
      })
    }, [documents])

  const { keyCharacteristicTypes } = useFetchKeyCharacteristicTypes(
    mainIds.requirementId
  )

  const complianceMethodsDictionary = useSelector<RootState, DictionaryEntry[]>(
    state => state.dictionaries.complianceMethods
  )
  const complianceMethodOptions = complianceMethodsDictionary.map(item => {
    return { text: item.name, value: item.id }
  })

  const [startSave, setStartSave] = useState(false)
  const errorsAreShown = useRef<boolean>(false)

  const { tabMarking, tabMarksToIcon, mapToJson } = useMarkingsOnTabs(data)

  // tab1

  const {
    areDocumentsApplicable,
    areDocumentsApplicableComment,
    documentSections
  } = data

  const [_areDocumentsApplicable, setAreDocumentsApplicable] =
    useState<AreDocumentsApplicable>(areDocumentsApplicable)

  const [_areDocumentsApplicableComment, setAreDocumentsApplicableComment] =
    useState(areDocumentsApplicableComment)

  const [_documentSectionsSelected, setDocumentSectionsSelected] = useState(
    documentSections.map(item => item.id)
  )

  useEffect(() => {
    setAreDocumentsApplicable(data.areDocumentsApplicable)
    setAreDocumentsApplicableComment(data.areDocumentsApplicableComment)
    setDocumentSectionsSelected(data.documentSections.map(item => item.id))
  }, [data])

  // tab2

  const { condensedSummaries } = data

  const [_summaries, setSummaries] = useState(
    condensedSummaries.length === 0 ? [''] : condensedSummaries
  )

  useEffect(() => {
    setSummaries(data.condensedSummaries)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // tab3

  const { keyCharacteristics } = data

  const [_keyCharacteristics, setKeyCharacteristics] = useState(
    keyCharacteristics.length === 0
      ? [
          {
            id: '0',
            keyCharacteristicTypeId: '',
            plotData: '',
            comment: ''
          }
        ]
      : keyCharacteristics
  )

  const setKeyCharacteristicsMapper = (
    keyCharacteristics: KeyCharacteristicsResult[]
  ) => {
    setKeyCharacteristics(keyCharacteristics)
  }

  useEffect(() => {
    setKeyCharacteristics(data.keyCharacteristics)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // tab4

  const { compliances } = data

  const [_compliances, setCompliances] = useState(
    compliances.length === 0
      ? [
          {
            id: '',
            isApplicable: IsComplianceApplicable.No,
            level: ComplianceLevel.Unit,
            comment: '',
            method: { id: '', name: ' - ', countryId: ' - ' },
            documentSection: { id: '', name: ' - ', documentShortName: ' - ' }
          }
        ]
      : compliances
  )

  useEffect(() => {
    setCompliances(data.compliances)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  //

  const mapCompliancesForPost = (complianceResults: ComplianceResult[]) => {
    if (complianceResults.length !== 0)
      return complianceResults.map((item: ComplianceResult) => {
        const {
          level,
          isApplicable,
          documentSection,
          complianceMethod,
          comment
        } = item
        if (isApplicable === 0)
          return {
            complianceLevel: level,
            isApplicable,
            comment,
            documentSectionId: documentSection?.id,
            complianceMethodId: complianceMethod?.id
          }
        return {
          complianceLevel: level,
          isApplicable,
          comment,
          documentSectionId: documentSection?.id,
          complianceMethodId: complianceMethod?.id
        }
      })
    //
    return []
  }

  useEffect(() => {
    if (startSave) {
      let errorOnStep = getStepWithError(
        _areDocumentsApplicable,
        _areDocumentsApplicableComment,
        _summaries,
        _keyCharacteristics,
        _compliances
      )

      const documentsData: MatrixDetailsDocuments = {
        areDocumentsApplicable: _areDocumentsApplicable,
        areDocumentsApplicableComment: _areDocumentsApplicableComment,
        documentSections: Array.from(
          new Set<string>([..._documentSectionsSelected])
        )
      }
      const summariesData = [..._summaries]
      const compliancesData = mapCompliancesForPost(_compliances)

      const ReadyFlags: ReadyFlags = mapToJson()

      setCountryRequirementMatrixDetails(
        mainIds,
        ReadyFlags,
        documentsData,
        summariesData,
        _keyCharacteristics,
        compliancesData,
        matrixId
      )
        .then(({ status, data }) => {
          if (status === 204 || status === 201) {
            if (matrixId || data.id)
              getCountryRequirementMatrixDetails(matrixId || data.id).then(
                matrixDetails => {
                  updateData(matrixDetails)
                  setActiveTab(0)
                  showSuccess('Success Your changes were saved.')
                }
              )
          }
        })
        .catch(error => {
          const { message } = error.response.data
          showError(
            message ||
              "Error. Something went wrong - we couldn't save your changes. Please try later."
          )
        })

      errorsAreShown.current = errorOnStep !== 0
    }

    setStartSave(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _areDocumentsApplicable,
    _areDocumentsApplicableComment,
    _compliances,
    _documentSectionsSelected,
    _keyCharacteristics,
    _summaries,
    mainIds,
    matrixId,
    startSave,
    updateData
  ])

  return (
    <TabsOverridesStyles>
      <Tabs
        id="section-tabs"
        size="sm"
        mt={2}
        mb={1}
        pb={0}
        onTabClick={tabNum => {
          const _activeTab = tabMarking(activeTab, tabNum)
          setActiveTab(_activeTab)
        }}
        activeTabId={activeTab}>
        <Tab icon={tabMarksToIcon(0)} title="Documents">
          <DocumentsTabUI
            countryDocumentsSectionTree={countryDocumentsSectionTree || []}
            errorsAreShown={errorsAreShown.current}
            areDocumentsApplicable={_areDocumentsApplicable}
            setAreDocumentsApplicable={setAreDocumentsApplicable}
            areDocumentsApplicableComment={_areDocumentsApplicableComment}
            setAreDocumentsApplicableComment={setAreDocumentsApplicableComment}
            documentSectionsSelected={_documentSectionsSelected}
            setDocumentSectionsSelected={setDocumentSectionsSelected}
          />
        </Tab>
        <Tab icon={tabMarksToIcon(1)} title="Condensed summary's">
          <SummariesTabUI
            errorsAreShown={errorsAreShown.current}
            summaries={_summaries}
            setSummaries={setSummaries}
          />
        </Tab>
        <Tab icon={tabMarksToIcon(2)} title="Key characteristics:">
          <KeyCharacteristicsTabUI
            errorsAreShown={errorsAreShown.current}
            keyCharacteristics={_keyCharacteristics}
            setKeyCharacteristics={setKeyCharacteristicsMapper}
            keyCharacteristicTypes={keyCharacteristicTypes}
          />
        </Tab>
        <Tab icon={tabMarksToIcon(3)} title="Compliance">
          <CompliancesTabUI
            errorsAreShown={errorsAreShown.current}
            compliances={_compliances}
            setCompliances={setCompliances}
            complianceMethodOptions={complianceMethodOptions}
            countryDocumentsSectionTree={countryDocumentsSectionTree}
          />
        </Tab>

        <Tab icon="" title={'SAVE'} onClick={() => setStartSave(true)}>
          <Spinner text="Updating record..." m={3} />
        </Tab>
      </Tabs>
    </TabsOverridesStyles>
  )
}
