import {
  Box,
  Button,
  Divider,
  H1,
  IconButton,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Tr,
  Switch
} from '@veracity/vui'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  DocumentStatusModel,
  updateCountryDocuments
} from '../../api/country-documents'
import { Empty } from '../../components/Empty'

import { PageBreadcrumbs } from '../../components/PageBreadcrumbs'
import { useFetchCountry } from '../../hooks/useFetchCountry'
import { useFetchCountryDocuments } from '../../hooks/useFetchCountryDocuments'
import { useFetchDocuments } from '../../hooks/useFetchDocuments'
import { useSendUpdateDocumentStatus } from '../../hooks/useSendUpdateDocumentStatus'

interface DocumentsForCountryModel {
  key: string
  name: string
  isPublished: boolean
}

export const DocumentsForCountryPage = () => {
  const { countryId } = useParams()
  // const { showError, showSuccess } = useToast()

  const { inProgress: fetchInProgress, country } = useFetchCountry(countryId)
  const { inProgress: fetchInProgressDoc, documents } = useFetchDocuments()
  const {
    inProgress: fetchInProgressCountryDoc,
    documents: documentsForCountryDB,
    fetchCountryDocuments
  } = useFetchCountryDocuments(countryId)
  const { inProgress: updateDocumentStatus, sendUpdateDocumentStatusRequest } =
    useSendUpdateDocumentStatus()

  const changeDocumentsForCountry = (
    countryId: string | undefined,
    newDocumentsForCountry: DocumentsForCountryModel[]
  ) => {
    if (!countryId) return null
    updateCountryDocuments(
      countryId,
      newDocumentsForCountry.map(item => item.key)
    ).then(() => fetchCountryDocuments(countryId))

    setNewDocumentsForCountry(undefined)
    setIndexForEdit(undefined)
  }

  const [newDocumentsForCountry, setNewDocumentsForCountry] = useState<string>()
  const [indexForEdit, setIndexForEdit] = useState<string>()

  const _documentsForCountry: DocumentsForCountryModel[] | [] = useMemo(() => {
    if (documentsForCountryDB === undefined) return []

    let _docs = documentsForCountryDB.map(elem => {
      const _document: DocumentsForCountryModel = {
        key: elem.id,
        name: elem.name,
        isPublished: elem.isPublished
      }
      return _document
    })

    if (indexForEdit === '')
      return [{ key: '', name: '', isPublished: false }, ..._docs]

    return _docs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsForCountryDB, indexForEdit])

  const _options = useMemo(() => {
    if (documents === undefined) return []
    if (documentsForCountryDB === undefined) return []
    if (indexForEdit === undefined) return []

    let _documentsForCountry = documentsForCountryDB.map(item => item.id)
    const _index = _documentsForCountry.indexOf(indexForEdit)
    if (_index !== -1) _documentsForCountry.splice(_index, 1)
    return (
      documents
        .filter(document => _documentsForCountry.indexOf(document.id) === -1)
        .map(document => {
          return {
            text: document.name,
            value: document.id
          }
        }) || []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, documentsForCountryDB, indexForEdit])

  if (
    fetchInProgress ||
    fetchInProgressDoc ||
    fetchInProgressCountryDoc ||
    updateDocumentStatus
  )
    return (
      <Spinner
        text="Loading country document's..."
        m={10}
        ml={'auto'}
        mr={'auto'}
      />
    )

  const handlePublishSwitch = (id: string, value: boolean) => {
    value = !value
    const documentModel: DocumentStatusModel = {
      isPublished: value,
      documentId: id
    }

    if (!countryId) return null
    sendUpdateDocumentStatusRequest(documentModel, countryId).then(() => {
      fetchCountryDocuments(countryId)
    })
  }

  const countryName = country?.name
  return (
    <Box column minW="100%">
      <PageBreadcrumbs
        items={[
          { label: 'Countries', to: `/countries/` },
          { label: `${countryName} documents` }
        ]}
      />

      <Box p="0 1em" justifyContent="space-between" center>
        <H1>{`Documents linked with ${countryName}`}</H1>

        <Button
          iconLeft="falPlus"
          text="Add"
          disabled={!!indexForEdit || indexForEdit === ''}
          onClick={() => setIndexForEdit('')}
        />
      </Box>

      <Divider borderColor="seaBlue.28" />

      <Table>
        <Tbody>
          {_documentsForCountry?.length === 0 ? (
            <Empty />
          ) : (
            _documentsForCountry?.map(item => (
              <Tr key={item.key} data-key={item.key}>
                <Td title="Document name" p="2">
                  {indexForEdit !== item.key && (item?.name || '')}
                  {indexForEdit === item.key && (
                    <Select
                      matchWidth={true}
                      options={_options}
                      onChange={e => setNewDocumentsForCountry(e.target.value)}
                      value={
                        newDocumentsForCountry ||
                        _documentsForCountry[
                          _documentsForCountry.findIndex(
                            elem => elem.key === item?.key
                          )
                        ].key ||
                        ''
                      }>
                      {_options.map(option => (
                        <Select.Option key={option.value} {...option} />
                      ))}
                    </Select>
                  )}
                </Td>
                <Td w="10rem">
                  {indexForEdit === item.key ? (
                    <Box gap="1">
                      <IconButton
                        icon="falSave"
                        size="sm"
                        onClick={() => {
                          if (
                            documents &&
                            newDocumentsForCountry &&
                            item?.key !== undefined
                          ) {
                            let _indexOld = _documentsForCountry.findIndex(
                              elem => elem.key === indexForEdit
                            )
                            let _indexNew = documents.findIndex(
                              elem => elem.id === newDocumentsForCountry
                            )

                            _documentsForCountry[_indexOld] = {
                              key: documents[_indexNew].id,
                              name: documents[_indexNew].name,
                              isPublished: documents[_indexNew].isPublished
                            }

                            changeDocumentsForCountry(
                              countryId,
                              _documentsForCountry
                            )
                          }
                        }}
                      />
                      <IconButton
                        icon="falTimesCircle"
                        size="sm"
                        onClick={() => setIndexForEdit(undefined)}
                      />
                    </Box>
                  ) : (
                    <Box gap="1">
                      <Switch
                        mr={2}
                        size="sm"
                        checked={item.isPublished}
                        onClick={() =>
                          handlePublishSwitch(item.key, item.isPublished)
                        }
                      />
                      <IconButton
                        icon="falPen"
                        size="sm"
                        onClick={() => setIndexForEdit(item.key)}
                      />
                      <IconButton
                        icon="falTrash"
                        size="sm"
                        onClick={() => {
                          const _index = _documentsForCountry.findIndex(
                            elem => elem.key === item.key
                          )
                          _documentsForCountry.splice(_index, 1)

                          changeDocumentsForCountry(
                            countryId,
                            _documentsForCountry
                          )
                        }}
                      />
                    </Box>
                  )}
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
