import axios from './base'

export enum UserRoles {
  Admin = 99,
  Moderator = 1,
  Viewer = 0,
  Unknown = -1
}

export interface UsersListResult {
  id: string
  firstName: string
  lastName: string
  fullName: string
  email: string
  role?: UserRoles
}

export const getUsersList = () =>
  axios.get(`/api/users`).then(response => response.data as UsersListResult[])

export const postInvite = (email: string) =>
  axios.post(`/api/users/invite`, { email })

export const setRole = (userId: string, role: UserRoles) =>
  axios.put(`/api/Users/${userId}/role`, { role })

export const removeUser = (userId: string) =>
  axios.delete(`/api/users/${userId}`)
