import {
  Table,
  Tr,
  Td,
  Button,
  ButtonToggleGroup,
  Thead,
  Tbody
} from '@veracity/vui'
import { landGreen } from '@veracity/vui/dist/cjs/theme/foundations/colors'

import { useSelector } from 'react-redux'
import { Link as RRLink } from 'react-router-dom'
import { RequirementsListElement } from '.'
import { ReadyFlags } from '../../api/country-requirement-matrixes'
import { RootState } from '../../store'
import { DictionaryEntry } from '../../store/slices/dictionaries'
import { RfgClassificationTableBox } from './RfgClassificationTableBox'

interface matrix extends ReadyFlags {
  id: string
  name: string
  summaries: string
}

interface TechnologyMatrix {
  technologyId: string
  technologyName: string
  matrices: matrix[]
}

interface TechnologiesTableProps {
  technologyArr: TechnologyMatrix[]
  countryId: string
  requirement: RequirementsListElement
  setInModalData: (x: {
    requirement: string
    technology: string
    idsArr: string[]
  }) => void
  setIsModalOpen: (x: boolean) => void
}
const TechnologiesTable = ({
  technologyArr,
  countryId,
  requirement,
  setInModalData,
  setIsModalOpen
}: TechnologiesTableProps) => {
  const rfgClassificationTypes = useSelector<RootState, DictionaryEntry[]>(
    state => state.dictionaries.rfgClassificationTypes
  )

  const rfgColumns = [
    { field: 'name', title: 'Technology', style: { minWidth: '150px' } },
    ...rfgClassificationTypes.map(elem => {
      return { field: elem.id, title: elem.name, style: { minWidth: '200px' } }
    })
  ]

  return (
    <Table
      pt={2}
      columns={rfgColumns}
      stickyColumn={true}
      overflowX="auto"
      display="block">
      <Thead />
      <Tbody>
        {technologyArr.map((technology: TechnologyMatrix) => (
          <Tr key={technology.technologyId} id={technology.technologyId}>
            <Td fontSize="sm">{technology.technologyName}</Td>
            {rfgClassificationTypes.map(rfgType => {
              const objectKey = Object.keys(technology.matrices).indexOf(
                rfgType?.id
              )
              const _currentTechnologyMatrix = Object.values(
                technology.matrices
              )[objectKey]

              const {
                areDocumentsReadyFlag,
                areCondensedSummariesReadyFlag,
                areKeyCharacteristicsReadyFlag,
                areCompliancesReadyFlag
              } = _currentTechnologyMatrix

              const tdColor =
                areDocumentsReadyFlag &&
                areCondensedSummariesReadyFlag &&
                areKeyCharacteristicsReadyFlag &&
                areCompliancesReadyFlag
                  ? landGreen[95]
                  : 'white'

              if (countryId === undefined)
                return <Td id={'error'} key={'error'}></Td>
              return (
                <Td id={rfgType.id} key={rfgType.id} bg={tdColor}>
                  {RfgClassificationTableBox(rfgType.id, technology.matrices)}
                  <ButtonToggleGroup mb={1}>
                    <Button
                      isActive={true}
                      onClick={() => {
                        const _inModalData = {
                          requirement: requirement.name,
                          technology: technology.technologyName,
                          idsArr: [
                            countryId || '',
                            requirement.id,
                            technology.technologyId,
                            rfgType.id,
                            _currentTechnologyMatrix?.id
                          ]
                        }

                        setInModalData(_inModalData)
                        setIsModalOpen(true)
                      }}>
                      {_currentTechnologyMatrix?.id ? 'Update' : 'Add'}
                    </Button>
                    {_currentTechnologyMatrix?.id && (
                      <Button
                        as={RRLink}
                        to={`/matrix/${_currentTechnologyMatrix?.id}`}>
                        Details
                      </Button>
                    )}
                  </ButtonToggleGroup>
                </Td>
              )
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default TechnologiesTable
