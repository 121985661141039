import { createSlice } from '@reduxjs/toolkit'

interface UserState {
  isLoggedIn?: boolean
  role: string
  fullName?: string
}

const initialState: UserState = {
  isLoggedIn: undefined,
  role: '',
  fullName: undefined
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      if (payload) {
        state.isLoggedIn = true
        state.fullName = payload.fullName
        state.role = payload.role
      } else {
        state.isLoggedIn = false
        state.fullName = undefined
        state.role = ''
      }
    }
  }
})

export const { reducer: userReducer, actions: userActions } = userSlice
