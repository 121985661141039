import { createSlice } from '@reduxjs/toolkit'

export interface DictionaryEntry {
  id: string
  name: string
  [key: string]: string
}

interface Dictionaries {
  countries: DictionaryEntry[]
  countrySummaries: DictionaryEntry[]
  technologies: DictionaryEntry[]
  rfgClassificationTypes: DictionaryEntry[]
  keyCharacteristicTypes: DictionaryEntry[]
  complianceMethods: DictionaryEntry[]
  documentSections: DictionaryEntry[]
  requirementCategories: DictionaryEntry[]
  requirements: DictionaryEntry[]
  thresholds: DictionaryEntry[]
}

interface DictionariesState extends Dictionaries {
  loading: boolean
}

const initialState: DictionariesState = {
  loading: false,
  countries: [],
  countrySummaries: [],
  technologies: [],
  rfgClassificationTypes: [],
  keyCharacteristicTypes: [],
  complianceMethods: [],
  documentSections: [],
  requirementCategories: [],
  requirements: [],
  thresholds: []
}

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    setCountries: (state, { payload }: { payload: any }) => {
      state['countries'] = payload
    },

    setCountrySummaries: (state, { payload }: { payload: any }) => {
      state['countrySummaries'] = payload
    },

    setClassificationTypes: (state, { payload }: { payload: any }) => {
      state['rfgClassificationTypes'] = payload
    },

    setKeyCharacteristicTypes: (state, { payload }: { payload: any }) => {
      state['keyCharacteristicTypes'] = payload
    },

    setComplianceMethods: (state, { payload }: { payload: any }) => {
      state['complianceMethods'] = payload
    },

    setDocumentSections: (state, { payload }: { payload: any }) => {
      state['documentSections'] = payload
    },

    setThresholds: (state, { payload }: { payload: any }) => {
      state['thresholds'] = payload
    },

    setDictionaries: (state, { payload }: { payload: Dictionaries }) => {
      Object.keys(payload).forEach(
        key =>
          (state[key as keyof Dictionaries] =
            payload[key as keyof Dictionaries])
      )
    },
    setLoading: (state, { payload }) => {
      state.loading = payload.value
    }
  }
})

export const { reducer: dictionariesReducer, actions: dictionariesActions } =
  dictionariesSlice
